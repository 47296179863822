<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-15 16:10:39
 * @LastEditTime: 2021-01-21 18:50:08
 * @FilePath: \acuconference-web\src\components\navigatorMenuList\groupList.vue
-->
<template>
  <v-list-item-group slot="" no-action v-model="test">
    <v-list-group
      v-for="groupList in list"
      :key="groupList.id"
      :prepend-icon="groupList.icon"
    >
      <template v-slot:activator>
        <v-list-item-title
          v-text="$t(groupList.label)"
        ></v-list-item-title>
      </template>

      <ItemList :level="level + 1" :list="groupList.subList" />
    </v-list-group>
  </v-list-item-group>
</template>

<script>
import ItemList from "./itemList";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return {};
      },
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ItemList,
  },
  data() {
    return {
      groupListActive: [false],
      test: 0
    };
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
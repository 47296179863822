/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 11:59:47
 * @LastEditTime: 2021-02-20 18:39:17
 * @FilePath: \acuconference-web\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '',
    name: 'No',
    redirect: '/joinmeeting',
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/joinmeeting',
  },
  {
    path: '/joinmeeting',
    name: 'Meeting',
    component: () => import(/* webpackChunkName: "meeting" */ '../views/JoinMeeting.vue'),
    props: true,
  },
  {
    path: '/joinmeeting/:inviteCode',
    name: 'JoinMeeting',
    component: () => import(/* webpackChunkName: "meeting" */ '../views/JoinMeeting.vue'),
    props: true,
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () => import(/* webpackChunkName: "loading" */ '../views/FullPageLoading.vue')
  },
  {
    path: '/hostmeeting',
    name: 'HostMeeting',
    component: () => import(/* webpackChunkName: "hostMeeting" */ '../views/HostMeeting.vue'),
    beforeEnter: (to, from, next) => {
      const storeUserInfo = store.state.userInfo
      if (storeUserInfo.currentUserInfo.userInfo.conferenceService) {
        next(true);
      } else {
        next('/joinmeeting')
      }
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.userInfo.currentUserInfo.acutoken) {
        next(false);
      } else {
        next(true)
      }
    },
    children: [{
      path: 'configuration',
      name: 'Configuration',
      beforeEnter: (to, from, next) => {
        if (!store.state.userInfo.currentUserInfo.displayPerms.includes("Configuration")) {
          next(false);
        } else {
          next(true)
        }
      },
      component: () => import(/* webpackChunkName: "configuration" */ '../views/admin/configuration.vue')
    }, {
      path: 'management',
      name: 'Management',
      beforeEnter: (to, from, next) => {
        if (!store.state.userInfo.currentUserInfo.displayPerms.includes("Management")) {
          next(false);
        } else {
          next(true)
        }
      },
      component: () => import(/* webpackChunkName: "management" */ '../views/admin/management.vue')
    }, {
      path: 'conferencelist',
      name: 'ConferenceList',
      beforeEnter: (to, from, next) => {
        if (!store.state.userInfo.currentUserInfo.displayPerms.includes("ConferenceList")) {
          next(false);
        } else {
          next(true)
        }
      },
      component: () => import(/* webpackChunkName: "conferenceList" */ '../views/admin/conferenceList.vue')
    }, {
      path: 'livesession',
      name: 'LiveSession',
      beforeEnter: (to, from, next) => {
        if (!store.state.userInfo.currentUserInfo.displayPerms.includes("ConferenceList")) {
          next(false);
        } else {
          next(true)
        }
      },
      component: () => import(/* webpackChunkName: "conferenceList" */ '../views/admin/liveSessions.vue')
    }, {
      path: 'report',
      name: 'Report',
      beforeEnter: (to, from, next) => {
        if (!store.state.userInfo.currentUserInfo.displayPerms.includes("Report")) {
          next(false);
        } else {
          next(true)
        }
      },
      component: () => import(/* webpackChunkName: "report" */ '../views/admin/report.vue')
    }, {
      path: 'overview',
      name: 'Overview',
      beforeEnter: (to, from, next) => {
        process.env.NODE_ENV === 'development' && console.log(store)
        if (!store.state.userInfo.currentUserInfo.displayPerms.includes("Overview")) {
          next(false);
        } else {
          next(true)
        }
      },
      component: () => import(/* webpackChunkName: "overview" */ '../views/admin/overview.vue')
    }]
  }
]
const router = new VueRouter({
  routes
})

export default router

/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-20 16:23:07
 * @LastEditTime: 2021-02-22 16:14:04
 * @FilePath: \acuconference-web\src\plugins\secretMaster.js
 */
import store from "../store/index"
const secretMaster = require("secret-master").setConfig({
  key: process.env.VUE_APP_SECRET_KEY
});
store.secretMaster = secretMaster
export default secretMaster

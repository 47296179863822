var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"elevation-0 v-bar--underline",attrs:{"app":"","color":_vm.$vuetify.theme.dark ? 'rgb(54, 54, 54)' : 'white'}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openDrawer')}}}):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('img',{staticStyle:{"position":"absolute","left":"50%","transform":"translateX(-50%)","height":"39px"},attrs:{"src":_vm.logo,"alt":""}}):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticStyle:{"position":"absolute","right":"16px","height":"100%"}},[(_vm.currentUserInfo.userInfo.id)?_c('div',{staticClass:"user-info-wrapper",style:({
        height: (_vm.$vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
      })},[_c('div',{staticClass:"username-wrapper text-md-subtitle-1"},[_c('span',{staticStyle:{"max-width":"200px","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(_vm.currentUserInfo.userInfo.name))])]),_c('div',{staticClass:"more-options-wrapper"},[_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',_vm._l((_vm.userActions),function(item){return _c('v-list-item',{key:item.id,attrs:{"link":""},on:{"click":function($event){return _vm.doUserAction(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1)]):_c('div',{staticClass:"user-info-wrapper",style:({
        height: (_vm.$vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
      })},[_c('div',{staticClass:"username-wrapper text-md-subtitle-1 login-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.$login($event)}}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("label_btn_login"))+" ")])],1)])]):_c('div',{staticStyle:{"position":"absolute","right":"16px","height":"100%"}},[(_vm.currentUserInfo.userInfo.id)?_c('div',{staticClass:"user-info-wrapper",style:({
        height: (_vm.$vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
      })},[_c('div',{staticClass:"username-wrapper text-md-subtitle-1"},[_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",staticStyle:{"padding":"0"},attrs:{"text":"","link":""}},'v-btn',attrs,false),on),[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',{staticClass:"avatar-wrapper",class:("elevation-" + (hover ? 6 : 0)),attrs:{"title":_vm.currentUserInfo.userInfo.name}},[_c('span',[_vm._v(_vm._s(_vm.computedAvatar))])])]}}],null,true)}),_c('svg',{staticClass:"v-icon__svg",staticStyle:{"font-size":"14px","height":"14px","width":"14px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","role":"img","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"}})])],1)]}}],null,false,283723379)},[_c('v-list',_vm._l((_vm.userActions),function(item){return _c('v-list-item',{key:item.id,attrs:{"link":""},on:{"click":function($event){return _vm.doUserAction(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1)]):_c('div',{staticClass:"user-info-wrapper",style:({
        height: (_vm.$vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
      })},[_c('div',{staticClass:"username-wrapper text-md-subtitle-1 login-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.$login($event)}}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("label_btn_login"))+" ")])],1)])]),_c('ChangePasswordDialog',{attrs:{"userId":_vm.currentUserInfo.userInfo.id,"enterpriseId":_vm.getCurrentUserEnterprise,"forceEditPassword":_vm.forceEditPassword},model:{value:(_vm.showChangePasswordDialog),callback:function ($$v) {_vm.showChangePasswordDialog=$$v},expression:"showChangePasswordDialog"}}),_c('Profile',{attrs:{"activeUser":_vm.currentUserInfo.userInfo,"enterpriseId":_vm.getCurrentUserEnterprise},on:{"finish":_vm.refreshUserData,"refreshSelfData":_vm.refreshUserData},model:{value:(_vm.showProfileDialog),callback:function ($$v) {_vm.showProfileDialog=$$v},expression:"showProfileDialog"}}),(_vm.show2FAEditType === 'add2FA')?_c('HeighSecuritySetting',{on:{"refresh":_vm.finishVerfiy2fa},model:{value:(_vm.showEdit2FADialog),callback:function ($$v) {_vm.showEdit2FADialog=$$v},expression:"showEdit2FADialog"}}):(_vm.show2FAEditType === 'verfiy2FA')?_c('Verfiy2fa',{attrs:{"forModify":true},on:{"refresh":_vm.finishVerfiy2fa},model:{value:(_vm.showEdit2FADialog),callback:function ($$v) {_vm.showEdit2FADialog=$$v},expression:"showEdit2FADialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
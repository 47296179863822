<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-19 17:26:37
 * @LastEditTime: 2021-03-05 15:18:03
 * @FilePath: \acuconference-web\src\components\dialogs\profile.vue
-->
<template>
  <v-dialog
    v-model="showProfileDialog"
    :persistent="editStatus"
    max-width="750"
  >
    <v-card>
      <v-card-title class="headline" v-if="user">
        <span>
          {{
            $t("title_dialog_profile", {
              user: user.name,
            })
          }}
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="showProfileDialog">
        <v-form v-model="valid" ref="form" :disabled="loading" v-if="user">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="enterprise.id"
                  maxLength="500"
                  :autofocus="false"
                  :label="$t('label_normal_Domain')"
                  v-model.trim="enterprise.domain"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="user.account"
                  @blur="blurAccount(user.account)"
                  :autofocus="false"
                  maxLength="50"
                  :disabled="!!this.user.id"
                  :label="$t('label_normal_account') + ' *'"
                  :rules="!!this.user.id ? requiredRules : rulseAccount"
                ></v-text-field>
                <!-- <v-text-field
                  @input="testHandleInput($event)"
                  :value.sync="user.account"
                  :autofocus="false"
                  maxLength="500"
                  :disabled="!!this.user.id"
                  :label="$t('label_normal_account') + ' *'"
                  :rules="rulseAccount"
                ></v-text-field> -->
                <v-text-field
                  v-if="
                    typeof enterprise.maxParticipants === 'number' &&
                    activeUser.type != 200 &&
                    !isSelf
                  "
                  v-model="user.maxParticipants"
                  :autofocus="false"
                  type="number"
                  :label="$t('label_input_label_max_participants') + '*'"
                  :rules="resultMaxParticipants"
                  maxLength="11"
                  @keydown="KeyboardEvent"
                ></v-text-field>
                <!-- :rules="
                    requiredRules.concat([
                      (v) => {
                        return ((0 < v)  && (v<= enterprise.maxParticipants)) || this.$t('error_max_participants_exceeded') + enterprise.maxParticipants;
                      },
                    ])
                  " -->
                <!-- only company admin can change user type -->
               
                <!-- <v-text-field
                  v-if="!user.id"
                  maxLength="500"
                  :rules="unRequiredPasswordCheck"
                  :label="$t('label_normal_password')"
                  v-model.trim="editPassword"
                >
                  ></v-text-field
                > -->
                <v-text-field
                  v-if="!user.id"
                  :label="$t('label_normal_password')"
                  v-model.trim="editPassword"
                  maxLength="500"
                  type="password"
                  :rules="unRequiredPasswordCheck.concat(passwordAddRules)"
                  required
                  @blur="editPasswords(editPassword)"
                  @keyup="editPasswordTrim"
                ></v-text-field>
                <v-text-field
                  v-if="!user.id"
                  maxLength="500"
                  type="password"
                   :rules="
                    passwordAddRules.concat([
                      (v) =>
                        (v === editPassword || v==='')||
                        'password not match',
                    ])
                  "
                  :label="$t('label_normal_enter_password')"
                  v-model.trim="repeatPassword"
                >
                  ></v-text-field
                >
                <!-- <v-btn
                  v-if="!!user.id && !isSelf"
                  :disabled="loading"
                  depressed
                  @click="showChangePasswordDialog = true"
                >
                  {{ $t("label_btn_change_password") }}
                </v-btn> -->
                <!-- 编辑信息  修改密码 -->
                <v-text-field
                  v-if="!!user.id && !isSelf"
                  :label="$t('label_normal_password')"
                  v-model.trim="editsPassword"
                  maxLength="500"
                  :rules="unRequiredPasswordCheck.concat(passwordCheckRules)"
                  required
                  type="password"
                  @blur="editPasswords(editsPassword)"
                  @keyup="editPasswordTrim"
                  @focus="editPasswordFocus"
                ></v-text-field>
                <v-text-field
                  v-if="!!user.id && !isSelf"
                  maxLength="500"
                  type="password"
                  :rules="
                    passwordCheckRules.concat([
                      (v) => v === editsPassword || 'password not match',
                    ])
                  "
                  :label="$t('label_normal_enter_password')"
                  v-model.trim="repeatPasswords"
                   
                >
                  ></v-text-field
                >
                <v-text-field
                  :autofocus="false"
                  maxLength="500"
                  :label="$t('label_input_label_telephone_no')"
                  v-model.trim="user.telephone"
                ></v-text-field>
                 <v-textarea
                  :label="$t('label_input_label_address')"
                  maxLength="2000"
                  rows="1"
                  v-model.trim="user.address"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="enterprise.name"
                  maxLength="500"
                  :label="$t('label_input_label_domain_name')"
                  v-model.trim="enterprise.name"
                  disabled
                ></v-text-field>
                <v-text-field
                  :label="$t('label_normal_user_display_name') + ' *'"
                  maxLength="500"
                  :autofocus="false"
                  :rules="requiredRules.concat(trimSpace)"
                  v-model.trim="user.name"
                  @blur="blurName(user.name)"
                ></v-text-field>
                 <v-select
                  v-if="canChangeUserType"
                  :items="userTypes"
                  v-model.trim="user.type"
                  item-text="label"
                  item-value="id"
                  :label="$t('label_normal_user_type')"
                ></v-select>
                <v-row v-if="!isSelf">
                  <v-checkbox
                    v-model="user.enableAccount"
                    :label="$t('label_input_label_enable_account')"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                  <!-- <v-checkbox
                    v-if="
                      !(
                        currentUserInfo.userInfo.type == '100' &&
                        forceType == '200'
                      )
                    "
                    v-model="user.enableRecord"
                    :disabled="!user.id && !enterprise.enableRecord"
                    :label="$t('label_input_label_enable_conference_record')"
                  ></v-checkbox> -->
                </v-row>
                <v-row v-if="activeUser && activeUser.type != 200 && !isSelf">
                  <v-checkbox
                    v-model="user.conferenceService"
                    :label="$t('label_input_label_conference_service')"
                  ></v-checkbox>
                  <v-spacer></v-spacer>
                </v-row>
                <v-text-field
                  :label="$t('label_input_label_email')"
                  maxLength="500"
                  @blur="blurEmail(user.email)"
                  v-model.trim="user.email"
                  :onkeyup="trimEmail"
                ></v-text-field>
                <v-text-field
                  :label="$t('label_normal_mobile')"
                  maxLength="500"
                  v-model.trim="user.mobile"
                ></v-text-field>
               
                <v-container>
                  <v-row>
                    <v-btn
                      v-if="couldShowResetBtn"
                      :disabled="loading"
                      depressed
                      @click="confirmReset2FA"
                    >
                      {{ $t("label_btn_title_reset_2fa") }}
                    </v-btn>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :disabled="loading"
          text
          @click="showProfileDialog = false"
          >{{ $t("label_btn_cancel") }}</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          v-if="!loading"
          :disabled="!valid || !enableSubmit || !enableConfirmation"
          @click="confirmEdit"
          >{{ $t("label_btn_confirm") }}</v-btn
        >
        <div class="loading-wrapper" v-else>
          <v-progress-circular
            indeterminate
            color="primary"
            width="2"
          ></v-progress-circular>
        </div>
      </v-card-actions>
    </v-card>
    <ChangePasswordDialog
      :oldPassword="activeUser && activeUser.type"
      :userId="user.id"
      :enterpriseId="enterpriseId"
      v-model="showChangePasswordDialog"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import apis from "../../mixins/apis";
import regx from "../../mixins/regCheckRulesMixin";
import ChangePasswordDialog from "./changePassword";
export default {
  mixins: [apis, regx],
  components: {
    ChangePasswordDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeUser: {
      type: Object,
      default() {
        return {};
      },
    },
    enterpriseId: {
      type: String,
      default: "",
    },
    forceType: Number,
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    enableConfirmation() {
      if(!!this.user.id && !this.isSelf){
        return this.editsPassword === this.repeatPasswords && this.repeatPasswords=== this.editsPassword
      }else{
        return this.editPassword!==''?this.editPassword === this.repeatPassword:true
      }
    },
    userTypes() {
      return [
        { id: 400, label: this.$t("label_select_user_type_normal") },
        { id: 300, label: this.$t("label_select_user_type_admin") },
      ];
    },
    canChangeUserType() {
      if (this.user.id) {
        if (this.user.id === this.currentUserInfo.userInfo.id) {
          // 自己不能修改自己的type
          return false;
        }
      }

      if (this.forceType === 200) {
        return false;
      }
      if (Number(this.currentUserInfo.userInfo.type) <= 300) {
        return true;
      }
      return false;
    },
    isSelf() {
      return this.currentUserInfo.userInfo.id === this.user.id;
    },
    couldShowResetBtn() {
      if (!this.user.id) {
        return false;
      }

      let permission = false;
      if (this.user.id && !this.user.twofaSecretCodeIsReset) {
        // 编辑用户且用户的2fa是开启状态
        if (this.user.type > this.currentUserInfo.userInfo.type) {
          // 自己的身份可以管理
          permission = true;
        }
      }
      // superAdmin例外
      const superAdminType = 100;
      if (this.currentUserInfo.userInfo.type === superAdminType) {
        permission = true;
      }

      return !this.user.twofaSecretCodeIsReset && permission;
    },
  },
  data() {
    return {
      showProfileDialog: false,
      valid: false,
      editStatus: true,
      showChangePasswordDialog: false,
      showVerfiy2FADialog: false,
      showEdit2FADialog: false,
      enableSubmit: false,
      enterprise: {},
      userType: "normal",
      user: {
        account: "",
        email: "",
        type: "",
        address: "",
        name: "",
        enableAccount: true,
        telephone: "",
        mobile: "",
        password: "",
        maxParticipants: 0,
        conferenceService: false,
      },
      editPassword: "",
      repeatPassword: "",
      editsPassword: "Acucone1@",
      repeatPasswords: "Acucone1@",
      typeChanged: false,
      required: [(v) => !!v || this.$t("error_tips_required_params")],
    };
  },
  created() {
    this.createNewUser();
  },
  watch: {
    value(val) {
      if (val !== this.showProfileDialog) {
        this.showProfileDialog = true;
      }
    },
    showProfileDialog(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
      if (val) {
        this.$nextTick(() => {
          this.initEditDatas();
        });
      } else {
        this.resetDialog();
      }
    },
    user: {
      deep: true,
      handler() {
        if (this.waitForChange) {
          this.waitForChange = false;
          this.enableSubmit = true;
        }
      },
    },
    userType() {
      this.typeChanged = true;
    },
    "user.account": {
      immediate: true,
      handler(val) {
        // this.trimAccount()
        const reg = new RegExp(
          "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
        );
        if (reg.test(val)) {
          // 满足邮箱格式
          this.user.email = val;
        }
      },
    },
  },
  methods: {
    ...mapMutations("userInfo", ["updateUserInfo"]),
    editPasswordFocus(){
      this.editsPassword=""
      this.repeatPasswords=""
    },
    async initEditDatas(afterVerfiyFA, needUpdateVueX) {
      await this.initCurrentUser(needUpdateVueX);
      await this.initEnterpriseInfo();
      if (afterVerfiyFA) {
        this.wantEdit2FA();
      }
      this.$nextTick(() => {
        this.waitForChange = true;
      });
    },
    KeyboardEvent(e) {
      if (
        e.keyCode === 190 ||
        e.keyCode === 189 ||
        e.keyCode === 109 ||
        e.keyCode === 69 ||
        e.keyCode === 107 ||
        e.keyCode === 187 ||
        e.keyCode === 110 ||
        e.keyCode === 229
      ) {
        e.returnValue = false;
      }
    },
    blurAccount(val) {
      this.user.account = val.replace(/\s+/g, "");
    },
    blurEmail(val) {
      this.user.email = val.replace(/\s+/g, "");
    },
    blurName(val) {
      this.user.name = val.replace(/\s+/g, "");
    },
    editPasswords(val) {
      this.editPassword = val.replace(/ /g, "");
      if(this.editsPassword===""){
        this.editsPassword="Acucone1@"
        this.repeatPasswords="Acucone1@"
      }
       if(this.editsPassword){
        this.enableSubmit = true
      }
    },
    editPasswordTrim() {
      this.editPassword = this.editPassword.replace(/^ +| +$/g, "");
    },
    // testHandleInput(value) {
    //   if (typeof value === "string") {
    //     this.user.account = value.trim();
    //   }
    // },
    trimAccount() {
      this.user.account = this.user.account.trim();
    },
    trimEmail() {
      if (this.user.email) {
        this.user.email = this.user.email.trim();
      }
    },
    refreshAfterSet2FA() {
      this.initEditDatas(undefined, true);
    },
    async confirmReset2FA() {
      const confirm = await this.$confirm.warn(
        this.$t("label_confirm_reset_2fa"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (confirm === "confirm") {
        const result = await this.resetUser2FA(this.user.id);
        if (result) {
          this.$toast.success(this.$t("prompt_success"));
          if (this.user.id === this.currentUserInfo.userInfo.id) {
            // 刷新自己的个人数据
            this.$emit("refreshSelfData");
          }
          this.refreshAfterSet2FA();
        }
      }
    },
    async initEnterpriseInfo() {
      let enterpriseId = this.enterpriseId;
      if (enterpriseId) {
        const result = await this.getEnterpriseById(enterpriseId);
        if (result) {
          this.enterprise = result;
          if (!this.user.id) {
            if (this.enterprise.enableRecord) {
              this.user.enableRecord = true;
            }
          }
        }
      }
      return;
    },
    async finishVerfiy2fa() {
      const afterVerfiyFA = true;
      this.initEditDatas(afterVerfiyFA, true);
    },
    async initCurrentUser(needUpdateVueX) {
      if (this.activeUser.id) {
        const result = await this.getUserInfo(this.activeUser.id);
        if (result) {
          this.user = result.userInfo;
          this.userType = this.user.type === 400 ? "normal" : "admin";
          if (!!this.user.id && !this.isSelf) {
            this.editsPassword = "Acucone1@";
            this.repeatPasswords = this.editsPassword;
          }
          if (
            this.user.id === this.currentUserInfo.userInfo.id &&
            needUpdateVueX
          ) {
            this.updateUserInfo(JSON.parse(JSON.stringify(result)));
          }
        }
      } else {
        this.createNewUser();
      }
      return;
    },
    async confirmEdit() {
      if (!this.user.id) {
        this.user.password = btoa(this.editPassword);
      }
      if(!!this.user.id && !this.isSelf && this.editsPassword!=="Acucone1@"){
        this.user.password = btoa(this.editsPassword);
      }
      if (!this.user.id) {
        if (this.forceType) {
          this.user.type = this.forceType;
        }
      }
      const result = await this.saveEditUserProfile(
        JSON.parse(JSON.stringify(this.user)),
        this.enterpriseId
      );
      if (result) {
        this.$toast.success(this.$t("prompt_success"));
        this.showProfileDialog = false;
        if (this.user.id === this.currentUserInfo.userInfo.id) {
          this.$bus.$emit("update_self_info");
        }
        this.$emit("finish");
      }
    },
    createNewUser() {
      this.user = {
        account: "",
        email: "",
        type: 400,
        address: "",
        name: "",
        // enableAccount: true,
        telephone: "",
        mobile: "",
        password: "",
      };
    },
    getSelectUserType() {
      if (this.forceType) return this.forceType;
      switch (this.userType) {
        case "admin":
          return 300;
        case "normal":
        default:
          return 400;
      }
    },
    wantEdit2FA() {
      if (this.user.twofaSecretCodeIsReset) {
        // no 2fa, set
        this.showEdit2FADialog = true;
      } else {
        // reset 2fa
        this.showVerfiy2FADialog = true;
      }
    },
    resetDialog() {
      this.$refs.form.reset();
      this.enterprise = {};
      this.valid = false;
      this.$nextTick(() => {
        this.createNewUser();
        this.userType = "normal";
        this.showChangePasswordDialog = false;
        // this.user.enableAccount = true;
        this.waitForChange = false;
        this.typeChanged = false;
        this.editPassword = "";
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>

<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 13:00:30
 * @LastEditTime: 2021-03-02 12:28:15
 * @FilePath: \acuconference-web\src\components\headerTabs.vue
-->
<template>
  <v-app-bar
    class="elevation-0 v-bar--underline"
    app
    :color="$vuetify.theme.dark ? 'rgb(54, 54, 54)' : 'white'"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click.stop="$emit('openDrawer')"
    ></v-app-bar-nav-icon>
    <img
      :src="logo"
      v-if="$vuetify.breakpoint.mobile"
      alt=""
      style="
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 39px;
      "
    />
    <div
      style="position: absolute; right: 16px; height: 100%"
      v-if="!$vuetify.breakpoint.mobile"
    >
      <div
        class="user-info-wrapper"
        :style="{
          height: ($vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
        }"
        v-if="currentUserInfo.userInfo.id"
      >
        <div class="username-wrapper text-md-subtitle-1">
          <span
            style="max-width: 200px; overflow: hidden; text-overflow: ellipsis"
            >{{ currentUserInfo.userInfo.name }}</span
          >
        </div>
        <div class="more-options-wrapper">
          <v-menu bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="item in userActions"
                @click="doUserAction(item.id)"
                :key="item.id"
                link
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div
        class="user-info-wrapper"
        :style="{
          height: ($vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
        }"
        v-else
      >
        <div
          class="username-wrapper text-md-subtitle-1 login-wrapper"
          @click.stop="$login"
        >
          <v-btn text color="primary">
            {{ $t("label_btn_login") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div style="position: absolute; right: 16px; height: 100%" v-else>
      <div
        class="user-info-wrapper"
        :style="{
          height: ($vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
        }"
        v-if="currentUserInfo.userInfo.id"
      >
        <div class="username-wrapper text-md-subtitle-1">
          <v-menu bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="elevation-0"
                style="padding: 0"
                text
                link
                v-bind="attrs"
                v-on="on"
              >
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <div
                      :title="currentUserInfo.userInfo.name"
                      class="avatar-wrapper"
                      :class="`elevation-${hover ? 6 : 0}`"
                    >
                      <span>{{ computedAvatar }}</span>
                    </div>
                  </template>
                </v-hover>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  role="img"
                  aria-hidden="true"
                  class="v-icon__svg"
                  style="font-size: 14px; height: 14px; width: 14px"
                >
                  <path
                    d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                  ></path>
                </svg>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="item in userActions"
                @click="doUserAction(item.id)"
                :key="item.id"
                link
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div
        class="user-info-wrapper"
        :style="{
          height: ($vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
        }"
        v-else
      >
        <div
          class="username-wrapper text-md-subtitle-1 login-wrapper"
          @click.stop="$login"
        >
          <v-btn text color="primary">
            {{ $t("label_btn_login") }}
          </v-btn>
        </div>
      </div>
    </div>
    <ChangePasswordDialog
      v-model="showChangePasswordDialog"
      :userId="currentUserInfo.userInfo.id"
      :enterpriseId="getCurrentUserEnterprise"
      :forceEditPassword="forceEditPassword"
    />
    <Profile
      v-model="showProfileDialog"
      :activeUser="currentUserInfo.userInfo"
      :enterpriseId="getCurrentUserEnterprise"
      @finish="refreshUserData"
      @refreshSelfData="refreshUserData"
    />
    <HeighSecuritySetting
      v-if="show2FAEditType === 'add2FA'"
      @refresh="finishVerfiy2fa"
      v-model="showEdit2FADialog"
    />
    <Verfiy2fa
      :forModify="true"
      v-else-if="show2FAEditType === 'verfiy2FA'"
      @refresh="finishVerfiy2fa"
      v-model="showEdit2FADialog"
    />
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ChangePasswordDialog from "./dialogs/changePassword";
import Profile from "./dialogs/profile";
import Verfiy2fa from "./dialogs/verfiy2fa";
import HeighSecuritySetting from "./dialogs/heighSecuritySetting";
export default {
  props: ["value", "drawer"],
  components: {
    ChangePasswordDialog,
    Profile,
    HeighSecuritySetting,
    Verfiy2fa,
  },
  data() {
    return {
      currentTabSelect: 0,
      showProfileDialog: false,
      showChangePasswordDialog: false,
      showEdit2FADialog: false,
      forceEditPassword: false,
      show2FAEditType: "",
    };
  },
  watch: {
    currentTabSelect(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
    },
    "$route.path"(val) {
      const rootPath = val.split("/")[1];
      let targetIndex = 0;
      for (let index = 0; index < this.renderTabs.length; index++) {
        if ("/" + rootPath === this.renderTabs[index].goto) {
          targetIndex = index;
        }
      }
      this.currentTabSelect = targetIndex;
    },
    value(val) {
      if (val !== this.navigatorMenuSelect) {
        this.navigatorMenuSelect = val;
      }
    },
    showChangePasswordDialog(val) {
      if (!val) {
        if (this.forceEditPassword) {
          this.forceEditPassword = false;
          this.refreshUserData();
        }
      }
    },
    showEdit2FADialog(val) {
      if (!val) {
        this.show2FAEditType = "add2FA"
      }
    }
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    getCurrentUserEnterprise() {
      return this.currentUserInfo.userInfo.enterpriseId;
    },
    computedAvatar() {
      let userName = this.currentUserInfo.userInfo.name;
      if (userName) {
        userName = userName.split(" ");
      }
      console.log(userName);
      let targetStr = "";
      for (let index = 0; index < userName.length; index++) {
        const str = userName[index];
        console.log(str);
        targetStr += str.split("").shift();
        if (targetStr.length >= 3) {
          break;
        }
      }
      return targetStr.toUpperCase();
    },
    userActions() {
      const userHasReset2FA = this.currentUserInfo.userInfo
        .twofaSecretCodeIsReset;
      const nativeUserActions = [
        {
          label: this.$t("label_btn_profile"),
          id: "profile",
        },
        {
          label: this.$t("label_btn_change_password"),
          id: "changePassword",
        },
        {
          label: userHasReset2FA
            ? this.$t("label_high_security")
            : this.$t("label_btn_title_edit_2fa"),
          id: userHasReset2FA ? "2fa" : "reset2fa",
        },
        {
          label: this.$t("label_btn_logout"),
          id: "logout",
        },
      ];

      return nativeUserActions;
    },
    nativeTabs() {
      const tabs = [
        {
          id: "joinMeeting",
          goto: "/meeting",
          decideBy: true,
          label: "label_tab_JoinMeeting",
        },
        {
          id: "hostMeeting",
          goto: "/hostmeeting",
          decideBy: true,
          label: "label_tab_HostMeeting",
        },
      ];
      return tabs;
    },
    renderTabs() {
      const render = [];
      this.nativeTabs.forEach((tab) => {
        if (tab.decideBy) {
          render.push(tab);
        }
      });
      return render;
    },
    logo() {
      return process.env.VUE_APP_API_RES_HOST + process.env.VUE_APP_API_RES_LOGO
    }
  },
  mounted() {
    this.refreshUserData();
  },
  created() {
    this.$bus.$on('forceModifiyPassword', this.forceModifyPassword.bind(this));
  },
  methods: {
    ...mapActions("userInfo", ["logout", "refreshCurrentUserData"]),
    async finishVerfiy2fa(afterAction) {
      this.showEdit2FADialog = false;
      if (afterAction) {
        this.show2FAEditType = "add2FA"
        this.$nextTick(() => {
          this.showEdit2FADialog = true;
        });
      } else {
        await this.refreshUserData();
      }
    },
    async doUserAction(id) {
      // let confirm;
      switch (id) {
        case "logout":
          this.logout();
          this.$toast.info(this.$t("prompt_logout"));
          this.$nextTick(() => {
            this.$router
              .push({
                name: "Meeting",
              })
              .catch((err) => err);
          });
          break;
        case "profile":
          this.showProfileDialog = true;
          break;
        case "2fa":
          this.show2FAEditType = "add2FA"
          this.$nextTick(() => {
          this.showEdit2FADialog = true;
        });
          break;
        case "reset2fa":
          // confirm = await this.$confirm.warn(
          //   this.$t("label_confirm_reset_2fa_for_self"),
          //   {
          //     title: this.$t("label_warn"),
          //     buttons: [
          //       {
          //         label: this.$t("label_btn_cancel"),
          //         value: "cancel",
          //         color: "primary",
          //       },
          //       {
          //         label: this.$t("label_btn_confirm"),
          //         value: "confirm",
          //         color: "primary",
          //       },
          //     ],
          //   }
          // );
          // console.log(confirm);
          // if (confirm === "confirm") {
          // if (true) {
          //   this.showEdit2FADialog = true;
          // }
          this.show2FAEditType = "verfiy2FA"
          this.$nextTick(() => {
          this.showEdit2FADialog = true;
        });
          break;
        case "changePassword":
          this.showChangePasswordDialog = true;
          break;
        default:
          break;
      }
    },
    async refreshUserData() {
      try {
        const result = await this.refreshCurrentUserData();
        if (result && result.needChangePassword) {
          this.forceModifyPassword();
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.log(error);
      }
      return;
    },
    forceModifyPassword() {
      this.forceEditPassword = true;
      this.showChangePasswordDialog = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.v-bar--underline
  &.elevation-0
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)!important
.user-info-wrapper
  display: flex
  align-items: center
  justify-content: flex-end
  width: 150px
  .username-wrapper
    flex-grow: 1
    max-width: 100px
    overflow: hidden
    text-overflow: ellipsis
    display: flex
    justify-content: flex-end
    .avatar-wrapper
      position: relative
      width: 30px
      height: 30px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      background: #2196f3
      color: white
    &.login-wrapper
      cursor: pointer
      transition: all 0.3s
      :hover
        color: #2196f3
</style>
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 12:55:07
 * @LastEditTime: 2021-01-14 12:56:41
 * @FilePath: \acuconference-web\src\plugins\vueToastification.js
 */
// toast
import Vue from 'vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

function asyncGetContainer() {
  return new Promise(resolve => {
    const observer = new MutationObserver(function (mutations, me) {
      const myContainer = document.getElementById("app");
      if (myContainer) {
        me.disconnect();
        resolve(myContainer);
      }
    });
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  });
}
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  container: asyncGetContainer,
  timeout: 3000
});
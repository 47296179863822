<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 11:54:35
 * @LastEditTime: 2021-03-01 13:28:07
 * @FilePath: \acuconference-web\src\App.vue
-->
<template>
  <v-app id="app">
    <HeaderTabs
      @toggleDrawer="toggleDrawer"
      @openDrawer="drawer = true"
      :drawer="drawer"
      ref="header"
    />
    <v-divider />
    <v-navigation-drawer floating v-model="drawer" app>
      <Navigatior @closeDrawer="drawer = false" />
    </v-navigation-drawer>
    <v-main>
      <v-container fill-height fluid>
        <div class="router-view-wrapper">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>

    <v-footer app>
      <v-col cols="2"></v-col>
      <v-col class="text-center" cols="8">
        {{ "©2019 - " + new Date().getFullYear() + " " }}
        <strong>{{ productName }}</strong>
        {{ ". " + $t("label_footer_AllRightsReserved") }}
      </v-col>
      <v-menu open-on-hover top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" style="position: absolute; right: 10px">
            {{ $vuetify.breakpoint.mobile ? locale.display : locale.label }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              role="img"
              aria-hidden="true"
              class="v-icon__svg"
            >
              <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
            </svg>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="
              toggleDisplayLanguage({
                value: item,
              })
            "
            link
            v-for="(item, index) in localeList"
            active-class="highlighted"
            :class="locale.value === item.value ? 'highlighted' : ''"
            :key="index"
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-footer>
    <!-- <VersionDialog v-if="versionDialogShow" 
    :releaseNotes='releaseNotes' 
    :versionNumber='versionNumber'
    @showVersion="showVersion"></VersionDialog> -->
  </v-app>
</template>

<script>
import Navigatior from "./components/navigator";
import HeaderTabs from "./components/headerTabs";
//import VersionDialog from "./components/versionDialog"
import { mapActions, mapState } from "vuex";
import intervalReplaceAcuToken from "./mixins/intervalReplaceAcuToken";
export default {
  mixins: [intervalReplaceAcuToken],
  data() {
    return {
      drawer: true,
      currentActivePath: "joinMeeting",
      updateSelfInfoHandler: this.updateSelfInfo.bind(this),
      timer:null,
      releaseNotes:undefined,
      //versionDialogShow:false,
      versionNumber:1,
    };
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  computed: {
    ...mapState("locales", ["localeList", "locale"]),
    ...mapState("userInfo", ["currentUserInfo"]),
    productName() {
      return process.env.VUE_APP_PRODUCT_NAME;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const setAutoLogin = localStorage.getItem("AUTO_LOGIN");
      if (setAutoLogin) {
        this.$router.push({
          name: "Loading"
        });
      }
    });

    this.$bus.$on("update_self_info", this.updateSelfInfoHandler);
  },
  watch: {
    "locale.value"(val) {
      this.$vuetify.lang.current = val;
    },
    "currentUserInfo.acutoken"(val, oval) {
      if (!oval && val) {
        // 登陆成功，开始轮询替换
        this.intervalCheckAcuToken();
      } else if (oval && !val) {
        // 注销登录， 停止轮询替换
        this.shutdownIntervalCheckAcuToken();
      }
    }
  },
  created() {
    this.getVersionweb()
    this.initUserInfoFromLocalStorage();
    const locale = localStorage.getItem("SELECT_LOCALE") || this.$i18n.locale;
    this.toggleDisplayLanguage({
      value: locale,
      setToLocalStorage: false
    });
  },
  methods: {
    showVersion(){
       clearInterval(this.timer)
       //this.versionDialogShow=false
    },
    upVersionWeb(){
      this.timer=setInterval(()=>{
        this.getVersionweb()
      },7200000)
    },
    async getVersionweb() {
      try {
        clearInterval(this.timer)
        const api =process.env.VUE_APP_API_RES_HOST + process.env.VUE_APP_API_GET_VERSIONWEB;
          const result = await this.$axios.get(api,{
             transformRequest: (data, headers) => {
                  delete headers.common.acutoken
                  return data
              },
          });
          // 2021-8-25   刷新弹窗
          if(result.code===1){
            if(result.data.latest){
              const latest = result.data.latest
              if(latest.buildNumber > window.appConfig.appVersion){
                //this.versionDialogShow=true
                this.releaseNotes = latest.releaseNotes
                this.versionNumber=result.data.latest.buildNumber
                if (Object.prototype.toString.call(this.releaseNotes) !== "[object Array]") {
                  this.releaseNotes = []
                }
                //更新内容介绍releaseNotes
                clearInterval(this.timer)
                const confirm = await this.$confirm.warn(
                  this.releaseNotes.toString(),
                    {
                      title: this.$t('TITLE_NEW_VERSION_AVAILABLE') + ': V' + result.data.latest.buildNumber,
                      persistent:true,
                      buttons: [
                        {
                          label: this.$t("label_btn_refresh"),
                          value: "confirm",
                          color: "primary",
                        },
                      ],
                    }
                )
                 if (confirm === "confirm") {
                   location.reload()
                  }
                
              }
            }
            this.upVersionWeb()
          }   
      } catch (error) {
        return error;
      }
    },
    ...mapActions("userInfo", [
      "initUserInfoFromLocalStorage",
      "refreshCurrentUserData"
    ]),
    ...mapActions("locales", ["toggleDisplayLanguage"]),
    toggleDrawer(value) {
      this.drawer = value;
    },
    async updateSelfInfo() {
      try {
        const result = await this.refreshCurrentUserData();
        if (result && result.needChangePassword) {
          this.forceModifyPassword();
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.log(error);
      }
      return;
    }
  },
  components: {
    Navigatior,
    HeaderTabs,
    //VersionDialog,
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.router-view-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.highlighted {
  background: #eee;
}
.highlighted > div {
  color: #2196f3;
}
</style>

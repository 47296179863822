/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-29 15:07:50
 * @LastEditTime: 2021-03-04 12:22:23
 * @FilePath: \acuconference-web\src\mixins\regCheckRulesMixin.js
 */
export default {
  computed: {
    checkAccountRules() {
      return [
        (v) =>
          (v && !!v.match(/^[0-9a-zA-Z|~|!|@|#|$|%|^|&|*|(|)|_|+]*$/g)) ||
          this.$t("account_rules"),
        (v) =>
          (v && v.trim && !v.trim().match(/\s/g)) || this.$t("account_rules"),
      ];
    },
    onlyLetterNumber() {
      return [
        (v) =>
          (v && !!v.match(/^[0-9a-zA-Z]*$/g)) || this.$t("error_illegal_input"),
      ];
    },
    inviteCheck() {
      return [
        (v) =>
        (v && v.trim && !!v.trim().match(/^[0-9a-zA-Z_\-.]{6,50}$/g)) ||
          this.$t("error_invitation_code"),
      ]
    },
    noSpace() {
      return [
        (v) =>
          (v && v.trim && !v.trim().match(/\s/g)) ||
          this.$t("error_illegal_input"),
      ];
    },
    requiredRules() {
     return [(v) =>!!v || this.$t("prompt_login_error_all_paramter_needed")];
    },
    unRequiredPasswordCheck() {
      return [
        (v) =>
          v == "" ||
          (v != "" &&
            !!v &&
            !!v.match(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-^!$%^&*()_@#+|~=`{}[\]\\:";'<>?,./])[a-zA-Z0-9-|\\|^|@|#|!|$|%|^|&|*|(|)|_|+|||~|=|`|{|}|[|\]|:|"|;|'|<|>|?|,|.|/]{8,108}$/
              ///^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-^!$%^&*()_@#+|~=`{}[\]\\:";'<>?,./])[A-Za-z\d-^!$%^&*()_@#+|~=`{}[\]\\:";'<>?,./]{8,108}$/
            )) ||
            this.$t("error_password_format_illegal"),
      ];
    },
    strictFelidCheck() {
      return [
        (v) =>
          (v &&
            !!v.match(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-^!$%^&*()_@#+|~=`{}[\]\\:";'<>?,./])[a-zA-Z0-9-|\\|^|@|#|!|$|%|^|&|*|(|)|_|+|||~|=|`|{|}|[|\]|:|"|;|'|<|>|?|,|.|/]{8,108}$/i
            )) ||
          this.$t("error_password_format_illegal"),
      ];
    },
    passwordCheckRules() {
      return this.requiredRules.concat(this.strictFelidCheck);
    },
    passwordAddRules() {
      return [(v) => (v=='' ||v!=='' ||'')];
    },
    maxLength500() {
      return [
        (v) =>
          (v.length ? v.length < 500 : true) ||
          this.$t("error_max_length_exceeded"),
      ];
    },

    maxLength2000() {
      return [
        (v) =>
          (v.length ? v.length < 2000 : true) ||
          this.$t("error_max_length_exceeded"),
      ];
    },
    checkAccount() {
      return [
        (v) =>
          (v &&
            !!v.match(
              /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
            )) ||
          this.$t("error_email_format"),
      ];
    },
    rulseAccount() {
      const requireFormat = this.requiredRules.concat(this.trimSpace);
      return requireFormat.concat([
        (v) => (v && !!v.match(/^[0-9a-zA-Z_\-.]{2,50}$/)) || this.$t("error_length_chaoguole_account"),
      ]);
    },
    rulseDomain() {
      const requireFormat = this.requiredRules.concat(this.trimSpace);
      return requireFormat.concat([
        (v) => (v && !!v.match(/^[0-9a-zA-Z_\-.]{2,50}$/)) || this.$t("error_length_chaoguole_domain"),
      ]);
    },
    trimSpace() {
      return [
        (v) => {
          v +=''
          if ( v && (v.replace(/ /g, "") === "")) {
            return this.$t("prompt_login_error_all_paramter_needed");
          } else {
            return true
          }
        },
      ];
    },
    trim(){
      return [
        (v) =>{
         return (v && !!v.match(/^\S*$/) ||this.$t('error_trim_space') )
        }
      ]
    },
    resultEmail() {
      return [
        (v) =>
          (v && !!v.match(/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/)) || this.$t("prompt_error"),
      ];
    },
    resultMaxParticipantsNumber(){
      return this.requiredRulesMaxParticipants.concat([
        (v) => {
          if(v===0 || v==='0'){
            return true
          }else{
            v +=''
           return  v && !!v.match(/^(0|[1-9]\d{0,9})$/) && v<=1000|| this.$t('error_negative_number')
          }
        },
      ]);
    },
    resultMaxParticipants(){
      return this.requiredRulesMaxParticipants.concat([
        (v) => {
          if(v===0 || v==='0'){
            return true
          }else{
            v +=''
           return  v && !!v.match(/^(0|[1-9]\d{0,9})$/) || this.$t('error_negative')
          }
        },
      ]);
    },

    requiredRulesMaxParticipants(){
      return [
        (v) =>{
          v +=''
        console.log(typeof v)
          if(v===''){
            return this.$t("prompt_login_error_all_paramter_needed")
          }else if(v===0 || v==='0'){
            return true
          }else{
            v +=''
            return v && !!v || this.$t("prompt_login_error_all_paramter_needed")
          }
        }
      ]
    }
  },
};

/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 15:00:17
 * @LastEditTime: 2021-01-14 15:09:54
 * @FilePath: \acuconference-web\src\store\modules\navigationMenuList\index.js
 */
import state from "./state"

export default {
  namespaced: true,
  state
}
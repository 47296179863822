<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 14:50:25
 * @LastEditTime: 2021-01-25 17:36:36
 * @FilePath: \acuconference-web\src\components\navigatorMenuList\navigatorMenuList.vue
-->
<template>
  <div>
    <ItemList :list="renderMenuList" :level="0" />
    <GroupList :list="renderNavigationMenuList" :level="0" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ItemList from "./itemList";
import GroupList from "./groupList";
export default {
  components: {
    ItemList,
    GroupList,
  },
  computed: {
    ...mapState("navigationMenuList", ["menuList", "featureMenuList"]),
    ...mapState("userInfo", ["currentUserInfo"]),
    renderNavigationMenuList() {
      const nativeLists = this.featureMenuList;
      const currentPermissions = this.currentUserInfo.displayPerms;
      const renderList = [];
      nativeLists.forEach((list) => {
        let subList = [];
        if (list.subList && list.subList.length) {
          list.subList.forEach((sub) => {
            if (currentPermissions.includes(sub.decideBy)) {
              subList.push(sub);
            }
          });
          if (subList.length > 0) {
            renderList.push(
              Object.assign({}, list, {
                subList,
              })
            );
          }
        }
      });
      return renderList;
    },
    renderMenuList() {
      const menuList = JSON.parse(JSON.stringify(this.menuList))
      const acutoken = JSON.parse(localStorage.getItem('NAMESPACE_USER_INFO_STATE_USERINFO'))
      if (
        (this.currentUserInfo.userInfo.type && this.currentUserInfo.userInfo.type < 300) ||
        (this.currentUserInfo.userInfo.conferenceService === false) 
        ) {
        menuList.pop()
      }
      if(!acutoken || (acutoken.acutoken==="")){
        menuList.pop()
      }
      return menuList;
    }
  },
};
</script>

<style lang="sass" scoped>
.active-tab-light
  &.theme--light
    color: red!important
</style>
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 11:18:23
 * @LastEditTime: 2021-02-20 17:02:59
 * @FilePath: \acuconference-web\src\utils\localStorageHandler.js
 */
class LocalStorageHandler {
  constructor() {
  }

  get(key, needFormatToObject) {
    let getResult = localStorage.getItem(key)
    if (needFormatToObject && getResult) {
      try {
        getResult = JSON.parse(getResult)
      } catch (error) {
        process.env.NODE_ENV === 'development' && console.log(error)
      }
    }
    return getResult === null ? undefined : getResult
  }

  set(key, value) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }
    localStorage.setItem(key, value)
  }

  remove(key) {
    localStorage.removeItem(key)
  }
}
const localStorageHandler = new LocalStorageHandler()
export default localStorageHandler
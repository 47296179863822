/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-27 15:01:17
 * @LastEditTime: 2021-01-29 11:35:18
 * @FilePath: \acuconference-web\src\directive\vHideInMobile.js
 */
import Vue from 'vue'
Vue.directive('hideInMobile', {
  bind: function (el, binding, vnode) {
    const isIPad = function () {
      return (/iPad/.test(navigator.platform)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    }
    const isMobile = function () {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        return true;
      } else {
        if (isIPad()) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (isMobile()) {
      
      if (typeof binding.value === 'boolean' && !binding.value) {
        return
      }
      // replace HTMLElement with comment node
      const comment = document.createComment(' ');
      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = ' ';
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;


      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    } else {
      if (typeof binding.value === 'boolean' && !binding.value) {
        // replace HTMLElement with comment node
      const comment = document.createComment(' ');
      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = ' ';
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;


      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
      }
    }
  }
})
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 11:54:35
 * @LastEditTime: 2021-03-02 15:21:31
 * @FilePath: \acuconference-web\src\main.js
 */
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import "./plugins/confirm"
import "./plugins/login"
import i18n from './i18n'
import router from './router'
import store from './store'
import "./plugins/vueToastification"
import "./plugins/errorTranslate"
import bus from "./eventBus/index"
import secretMaster from "./plugins/secretMaster"
import utils from "./plugins/utils/index"

import appConfig from "../appConfig.json"

window.appConfig = appConfig
//build:读写文件中的值，自动加1，
// 服务器要给一个接口，与window.appconfig版本号做对比，服务器的大，就window.弹窗提示

import "./directive/vHideInElectron"
import "./directive/vHideInMobile"

Vue.config.productionTip = false

Vue.prototype['$bus'] = bus
Vue.prototype['$secret'] = secretMaster
Vue.prototype['$utils'] = utils



// set locale display with navigator 
const locale = navigator.language.split('-')[0]
i18n.locale = locale
new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-20 14:46:57
 * @LastEditTime: 2021-02-20 14:57:45
 * @FilePath: \acuconference-web\src\plugins\login.js
 */

import store from "../store"
import Vue from "vue"
import vuetify from "./vuetify"
import i18n from "../i18n"
import login from "./login/index"
import bus from "../eventBus/index"

Vue.use(login, {
  vuetify, store, i18n, bus
})
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-03-02 15:19:53
 * @LastEditTime: 2021-03-09 15:16:03
 * @FilePath: \acuconference-web\src\plugins\utils\index.js
 */
import isMobile from "./isMobile";
import isSafari from "./isSafari";
import copyText from "./copyText";

export default {
  isMobile,
  isSafari,
  copyText
};



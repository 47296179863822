/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 11:54:56
 * @LastEditTime: 2021-03-05 16:16:40
 * @FilePath: \acuconference-web\src\plugins\networkErrorHandler\index.js
 */
const axiosErrorHandler = Object.create(null);

axiosErrorHandler.install = function (Vue, {
  i18n,
  errorMap,
}) {
  this.i18n = i18n;
  const {
    unknowErrorPrompt,
    errorCodeMap,
    silenceCode,
  } = errorMap;
  Vue.prototype.$errorTranslate = function (err) {
    if (err && err.headers && err.data) {
      err = err.data
    }
    if (typeof err === "string") {
      return err
    } else if (typeof err === "object") {
      if (err.code) {
        if (silenceCode[err.code]) {
          return undefined
        }
        if ((typeof err.code === "number") && (err.code < 0)) {
          return undefined
        }
        if (errorCodeMap[err.code]) {
          return i18n.t(errorCodeMap[err.code])
        } 
        else if (err.msg || err.message) {
          return err.msg
        } else {
          return i18n.t(unknowErrorPrompt)
        }
      } else if (err.message || err.msg) {
        return err.message || err.msg
      } else if (err.data && err.data.code) {
        if (errorCodeMap[err.data.code]) {
          return i18n.t(errorCodeMap[err.data.code])
        } else {
          return i18n.t(unknowErrorPrompt)
        }
      } else if (err.data && (err.data.msg || err.data.message)) {
        return err.msg
      }
    } else {
      return i18n.t(unknowErrorPrompt)
    }
  }
}

export default axiosErrorHandler
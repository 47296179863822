/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 11:58:59
 * @LastEditTime: 2021-01-14 12:00:33
 * @FilePath: \acuconference-web\src\plugins\errorTranslate.js
 */
import Vue from 'vue';
import errorCodeMap from "../utils/ErrorMap"
import i18n from "../i18n"
import ErrorTranslate from "./networkErrorHandler"


Vue.use(ErrorTranslate, {
  i18n, errorMap: errorCodeMap
})

/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-03-02 15:19:35
 * @LastEditTime: 2021-03-02 15:19:49
 * @FilePath: \acuconference-web\src\plugins\utils\isMobile.js
 */
const isIPad = function () {
  return (/iPad/.test(navigator.platform)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}
const isMobile = function () {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    return true;
  } else {
    if (isIPad()) {
      return true;
    } else {
      return false;
    }
  }
}

export default isMobile
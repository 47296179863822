<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-15 15:27:02
 * @LastEditTime: 2021-01-15 16:42:29
 * @FilePath: \acuconference-web\src\components\navigatorMenuList\itemList.vue
-->
<template>
  <v-list-item-group>
    <v-list-item
      :class="{
        'v-item--active': active === item.id,
      }"
      active-class="none-style"
      :style="{
        'padding-left': (level + 1) * 16 + 'px'
      }"
      v-for="(item, i) in list"
      :key="i"
      :to="item.goto"
    >
      <v-list-item-icon>
        <v-icon v-text="item.icon"></v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="$t(item.label)"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: undefined,
    };
  },
  watch: {
    "$route.path": {
      handler(val) {
        const path = val.split("/")[this.level + 1];
        this.active = path;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-03-02 15:21:59
 * @LastEditTime: 2021-03-02 15:29:04
 * @FilePath: \acuconference-web\src\plugins\utils\isSafari.js
 */
export default function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") === -1) {
      return true; // Safari
    }
  }
  return false;
}


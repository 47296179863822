/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-12 14:41:17
 * @LastEditTime: 2021-01-12 14:44:24
 * @FilePath: \acuconference-web\src\utils\darkModeUtil.js
 */
export const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

export function listenIsDarkModeChange(cb) {
  if (window.matchMedia) {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const newColorScheme = e.matches?"dark":"light";
      cb && cb(newColorScheme, e)
    })
  }
}
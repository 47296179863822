/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 15:11:56
 * @LastEditTime: 2021-02-18 17:11:19
 * @FilePath: \acuconference-web\src\store\modules\locales\actions.js
 */
import i18n from "../../../i18n"

import localStorageHandler from "../../../utils/localStorageHandler"
export default {
  toggleDisplayLanguage({ commit, state }, { value, setToLocalStorage }) {
    if (typeof setToLocalStorage !== 'boolean') {
      setToLocalStorage = true
    }
    if (typeof value === 'object') {
      value = value.value
    }
    if (value && state.localeList.find(locale => locale.value === value)) {
      // value valid
      i18n.locale = value
      setToLocalStorage && localStorageHandler.set("SELECT_LOCALE", value)
      commit('commitChangeLanguage', value)
    }
  }
}
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 12:00:49
 * @LastEditTime: 2021-03-01 12:46:37
 * @FilePath: \acuconference-web\src\plugins\axios.js
 */
"use strict";

import Vue from "vue";
import axios from "axios";
import store from "../store";
import router from "../router";
import bus from "../eventBus/index";
import vuetify from '../plugins/vuetify'; 
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css' 
Vue.use(VuetifyDialog, {
  context: {
   vuetify
  }
})
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

export const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (response.status === 200) {
      const data = response.data;
      const setAutoLogin = localStorage.getItem("AUTO_LOGIN");
      if (data.code) {
        switch (data.code) {
          case 1:
            return data;
          case 4001:
            // token Expired
            //如果没有自动登录登出
            if (!setAutoLogin) {
              bus.$toast.error(bus.$t("error_account_expired"));
              store.dispatch("userInfo/logout");
            } else {
              router.push("/loading");
            }
            throw data
            // 自动登录，正常的登录
          case 6001:
          case 6002:
          case 6022:
          case 6023:
          case 6025:
            bus.$toast.error(bus.$errorTranslate(data));
            store.dispatch("userInfo/logout");
            // bus.$nextTick(()=>{
            //   router.push("/joinmeeting")
            // })
             break;
          default:
        }
      }
    }
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  store.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;

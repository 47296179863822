/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-20 14:38:51
 * @LastEditTime: 2021-02-20 14:57:14
 * @FilePath: \acuconference-web\src\plugins\login\index.js
 */
import Login from "./login"
function Install(Vue, { vuetify, store, i18n, bus }) {
  const property = '$login'
  function createDialogCmp(options = {}) {
    const container = document.querySelector('[data-app=true]') || document.body
    const Ctor = Vue.extend(Object.assign({ vuetify, store, i18n, bus }, Login))
    return new Promise(resolve => {
      const cmp = new Ctor(Object.assign({}, {
        propsData: Object.assign({}, Vue.prototype[property].options, options),
        destroyed: () => {
          container.removeChild(cmp.$el)
          resolve(cmp.value)
        }
      }))
      container.appendChild(cmp.$mount().$el)
    })
  }

  Vue.prototype[property] = createDialogCmp
}

export default Install
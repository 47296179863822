/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 14:48:39
 * @LastEditTime: 2021-01-21 15:09:27
 * @FilePath: \acuconference-web\src\plugins\confirm\index.js
 */
import Confirm from './confirm.vue'

function Install(Vue, options = {}) {
  const property = options.property || '$confirm'
  delete options.property
  const vuetify = options.vuetify
  delete options.vuetify
  if (!vuetify) {
    process.env.NODE_ENV === 'development' && console.warn('Module vuetify-confirm needs vuetify instance. Use Vue.use(VuetifyConfirm, { vuetify })')
  }
  const Ctor = Vue.extend(Object.assign({ vuetify }, Confirm))
  function createDialogCmp(options) {
    const container = document.querySelector('[data-app=true]') || document.body
    return new Promise(resolve => {
      const cmp = new Ctor(Object.assign({}, {
        propsData: Object.assign({}, Vue.prototype[property].options, options),
        destroyed: () => {
          container.removeChild(cmp.$el)
          resolve(cmp.value)
        }
      }))
      container.appendChild(cmp.$mount().$el)
    })
  }

  function show(message, options = {}) {
    options.message = message
    return createDialogCmp(options)
  }

  function success(message, options = {}) {
    options = Object.assign({}, options, {
      type: 'success',
      icon: 'mdi-check-circle',
    })
    return show(message, options)
  }
  function error(message, options = {}) {
    options = Object.assign({}, options, {
      type: 'error',
      icon: 'mdi-close-circle',
    })
    return show(message, options)
  }
  function warn(message, options = {}) {
    options = Object.assign({}, options, {
      type: 'warning',
      icon: 'mdi-alert',
    })
    return show(message, options)
  }
  function info(message, options = {}) {
    options = Object.assign({}, options, {
      type: 'primary',
      icon: 'mdi-exclamation-thick',
    })
    return show(message, options)
  }

  Vue.prototype[property] = show
  Vue.prototype[property].error = error
  Vue.prototype[property].success = success
  Vue.prototype[property].warn = warn
  Vue.prototype[property].info = info
  Vue.prototype[property].options = options || {}
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Install)
}

export default Install
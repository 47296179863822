/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 14:38:02
 * @LastEditTime: 2021-01-21 14:38:02
 * @FilePath: \acuconference-web\src\plugins\confirm\confirm.js
 */
<template>
  <v-dialog
    eager
    @input="change"
    value="true"
    :max-width="width"
    :persistent="persistent"
    @keydown.esc="choose(false)"
  >
    <v-card tile>
      <v-toolbar v-if="Boolean(title)" dark :color="type" dense flat>
        <v-icon v-if="Boolean(icon)" left>{{ icon }}</v-icon>
        <v-toolbar-title class="white--text" v-text="title" />
      </v-toolbar>
      <v-card-text class="body-1 text-body-1 py-3" v-html="message" />
      <v-card-actions v-if="float === 'right'">
        <v-spacer />
        <v-btn
          v-for="(btn, index) in renderBtns"
          :key="index"
          :color="btn.color"
          :text="flatBtn ? btn.label : ''"
          @click="choose(btn.value)"
        >
          {{ flatBtn ? "" : btn.label }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="float === 'center'" class="justify-center">
        <v-btn
          v-for="(btn, index) in renderBtns"
          :key="index"
          :color="btn.color"
          :text="flatBtn ? btn.label : ''"
          @click="choose(btn.value)"
        >
          {{ flatBtn ? "" : btn.label }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="float === 'left'">
        <v-btn
          v-for="(btn, index) in renderBtns"
          :key="index"
          :color="btn.color || 'primary'"
          :text="flatBtn ? btn.label : ''"
          @click="choose(btn.value)"
        >
          {{ flatBtn ? "" : btn.label }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VIcon,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn,
} from "vuetify/lib";
export default {
  components: {
    VCard,
    VCardActions,
    VCardText,
    VDialog,
    VIcon,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VBtn,
  },
  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    float: {
      type: String,
      default: 'right'
    },
    type: {
      type: String,
      default: "warning",
    },
    icon: {
      type: String,
      default() {
        return '';
      },
    },
    message: {
      type: String,
      required: true,
    },
    flatBtn: {
      type: Boolean,
      default: false,
    },
    persistent: Boolean,
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 450,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    renderBtns() {
      if (this.buttons.length) {
        return this.buttons;
      } else {
        return [
          {
            value: "cancel",
            label: "Cancel",
            color: "primary",
          },
          {
            value: "confirm",
            label: "Confirm",
            color: "primary",
          },
          
        ];
      }
    },
  },
  mounted() {
    document.addEventListener("keyup", this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onEnterPressed);
  },
  methods: {
    onEnterPressed(e) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.choose('confirm');
      }
    },
    choose(value) {
      this.$emit("result", value);
      this.value = value;
      this.$destroy();
    },
    change() {
      this.$emit("result", false);
      this.$destroy();
    },
  },
};
</script>
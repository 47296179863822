/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 15:05:46
 * @LastEditTime: 2021-01-11 15:55:45
 * @FilePath: \acuconference-web\src\store\modules\locales\mutations.js
 */
export default {
  commitChangeLanguage(state, toggleTo) {
    state.locale = state.localeList.find(local => local.value === toggleTo)
  }
}
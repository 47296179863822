/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 14:29:00
 * @LastEditTime: 2021-01-21 14:50:39
 * @FilePath: \acuconference-web\src\plugins\confirm.js
 */
import vuetify from "./vuetify"
import confirm from "./confirm/index"
import Vue from "vue"

Vue.use(confirm, { vuetify })

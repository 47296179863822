/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-20 19:22:42
 * @LastEditTime: 2021-03-05 16:25:18
 * @FilePath: \acuconference-web\src\mixins\intervalReplaceAcuToken.js
 */
import { mapState, mapMutations } from "vuex"
import { repla } from "../utils/stringUtils"


export default {
  data() {
    return {
      interValCheckTimer: undefined
    }
  },
  compute: {
    ...mapState("userInfo", ["currentUserInfo"])
  },
  methods: {
    ...mapMutations("userInfo", ["updateUserInfo"]),
    intervalCheckAcuToken() {
      this.shutdownIntervalCheckAcuToken()
      this.interValCheckTimer = setTimeout(this.getNewToken.bind(this), 60 * 60 * 1000)
    },
    shutdownIntervalCheckAcuToken() {
      clearTimeout(this.interValCheckTimer)
      this.interValCheckTimer = undefined
    },
    async getNewToken(){
      const api = repla(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_CHANGE_ACUTOKEN, {
        token: this.currentUserInfo.acutoken,
      })
      try {
        const result = await this.$axios.get(api)
        const { code, data } = result
        if (code === 1 && data.acutoken) {
          this.updateUserInfo({
            acutoken: data.acutoken
          })
          this.intervalCheckAcuToken()
        } else if (code === 1 && data.code && data.code === 4001) {
          // token 过期之后就不用继续换了
          return
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
}
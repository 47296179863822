import { render, staticRenderFns } from "./headerTabs.vue?vue&type=template&id=4718de72&scoped=true&"
import script from "./headerTabs.vue?vue&type=script&lang=js&"
export * from "./headerTabs.vue?vue&type=script&lang=js&"
import style0 from "./headerTabs.vue?vue&type=style&index=0&id=4718de72&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4718de72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAppBar,VAppBarNavIcon,VBtn,VHover,VIcon,VList,VListItem,VListItemTitle,VMenu})

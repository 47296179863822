/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-18 16:20:09
 * @LastEditTime: 2021-03-04 11:18:15
 * @FilePath: \acuconference-web\src\mixins\apis.js
 */
import { repla } from "../utils/stringUtils";
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    tableLocal() {
      switch (this._i18n.locale) {
        case "zh":
          return "zh-CN";
        case "en":
          return "en-US";
        default:
          return "en-US";
      }
    },
    tableFooterOptions() {
      const pageIndex = this.page - 1;
      const currentEnd = this.page * this.limit;
      console.error(this.page);
      return {
        "items-per-page-options": [5, 10, 15],
        "items-per-page-text": this.$t("table_row_per_page"),
        "page-text": this.$t("table_page_text", {
          start: Math.max(pageIndex * this.limit, 1),
          end: Math.min(this.totalCount, currentEnd),
          total: this.totalCount,
        }),
      };
    },
  },
  methods: {
    async getListsFromServer(api, params) {
      this.loading = true;
      const requestApi = process.env.VUE_APP_API_HOST + api;
      try {
        const result = await this.$axios.get(requestApi, {
          params,
        });
        if (result.code === 1) {
          this.loading = false;
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.handleError(error);
      }
      this.loading = false;
    },

    async getEnterpriseById(id) {
      this.loading = true;
      const requestApi = repla(
        process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ENTERPRISE,
        {
          enterprise: id,
        }
      );
      try {
        const result = await this.$axios.get(requestApi);
        if (result.code === 1) {
          this.loading = false;
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.handleError(error);
      }
      this.loading = false;
      return;
    },

    async checkDomainValid(domain) {
      const requestApi =
        process.env.VUE_APP_API_HOST +
        process.env.VUE_APP_API_GET_DOMAIN_VALID +
        domain;
      try {
        const result = await this.$axios.get(requestApi);
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.handleError(error);
      }
      return {
        canUse: false,
      };
    },

    async saveEditEnterprise(enterprise) {
      this.loading = true;
      const requestApi =
        process.env.VUE_APP_API_HOST +
        process.env.VUE_APP_API_POST_EDIT_ENTERPRISE;
      try {
        const result = await this.$axios.post(requestApi, enterprise);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async saveEditUserProfile(user, enterpriseId) {
      this.loading = true;
      const requestApi =
        process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_POST_SAVE_USER;
      try {
        const result = await this.$axios.post(
          requestApi,
          Object.assign({}, user, {
            enterpriseId: enterpriseId,
          })
        );
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async resetUser2FA(uid) {
      this.loading = true;
      const requestApi = repla(
        process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_RESET_2FA,
        {
          userId: uid,
        }
      );
      try {
        const result = await this.$axios.get(requestApi);
        this.loading = false;
        if (result.code === 1) {
          return (
            result.data || {
              success: true,
            }
          );
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async getUserInfo(uid) {
      this.loading = true;
      const requestApi = repla(
        process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_USER_INFO,
        {
          user: uid,
        }
      );
      try {
        const result = await this.$axios.get(requestApi);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async getEmailInfo() {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_EMAIL_INFO;
        const result = await this.$axios.get(api);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async deleteUser(data) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_USERS;
        const result = await this.$axios.delete(api, {
          data,
        });
        this.loading = false;
        if (result.code === 1) {
          return {
            success: true,
          };
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async deleteDomainsApi(data) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_DOMAINS;
        const result = await this.$axios.delete(api, {
          data,
        });
        this.loading = false;
        if (result.code === 1) {
          return {};
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async saveEmailInfo(email) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_EMAIL_INFO;
        const result = await this.$axios.post(api, email);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },
    async deleteConference(params) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ROOMS;
        const result = await this.$axios.delete(api, {
          data: params,
        });
        this.loading = false;
        if (result.code === 1) {
          return {
            msg: "Success",
          };
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async getConferenceInfoApi(cid) {
      this.loading = true;
      try {
        const api = repla(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ROOM,
          {
            room: cid,
          }
        );
        const result = await this.$axios.get(api);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async getRoomsApi(params) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ROOMS;
        const result = await this.$axios.get(api, {
          params,
        });
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },
    async getViewlogList(params) {
      try {
        const api =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_SEARCH_GET_LOGS;
        const result = await this.$axios.get(api, {
          params,
        });
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.handleError(error);
        return;
      }
    },
    async getExportViewlogList(params) {
      try {
        const api =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_SEARCH_POST_LOGS;
        const result = await this.$axios.get(api, {
          params,
        });
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        this.handleError(error);
        return;
      }
    },
    async getUserRoomsApi(uid, params) {
      this.loading = true;
      try {
        const api = repla(
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_USER_ROOMS,
          {
            user: uid,
          }
        );
        const result = await this.$axios.get(api, {
          params,
        });
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    //终止会议
    async getTerminate(rid){
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_TERMINATE +
          rid;
        const result = await this.$axios.post(api);
        this.loading = false;
        if (result.code === 1) {
          return result;
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }

    },

    async getParticipantsPage(rid) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_APT_PARTICIPANT_PAGE +
          rid;
        const result = await this.$axios.get(api);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async submitEditRoom(room) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_EDIT_ROOM;
        const result = await this.$axios.put(api, room);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async submitAddRoom(room) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_EDIT_ROOM;
        const result = await this.$axios.post(api, room);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async getUserOverviewApi() {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_USER_OVERVIEW;
        const result = await this.$axios.get(api);
        this.loading = false;
        if (result.code === 1) {
          return result.data;
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async changePassword(data) {
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_CHANGE_SELF_PASSWORD;
        const result = await this.$axios.put(api, data);
        this.loading = false;
        if (result.code === 1) {
          return {};
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },

    async otherChangePassword(data){
      this.loading = true;
      try {
        const api =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_CHANGE_USER_PASSWORD;
        const result = await this.$axios.put(api, data);
        this.loading = false;
        if (result.code === 1) {
          return {};
        } else {
          throw result;
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
        this.loading && (this.loading = false);
        this.handleError(error);
        return;
      }
    },
    
    handleError(err) {
      let error = this.$errorTranslate(err)
      if (error) {
        this.$toast.error(error);
      }
    },
  },
};

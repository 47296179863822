/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 15:05:20
 * @LastEditTime: 2021-02-09 11:30:38
 * @FilePath: \acuconference-web\src\store\modules\locales\state.js
 */
export default () => ({
  locale: {
    value: 'en',
    label: 'English',
    display: 'en'
  },
  localeList: [
    {
      value: 'en',
      label: 'English',
      display: 'EN'
    }, {
      value: 'zh',
      label: '中文',
      display: 'ZH'
    }, {
      value: 'th',
      label: 'ไทย',
      display: 'TH'
    }
  ],
})
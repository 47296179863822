/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-20 14:55:56
 * @LastEditTime: 2021-03-01 12:27:40
 * @FilePath: \acuconference-web\src\eventBus\index.js
 */
import Vue from 'vue'
import i18n from '../i18n'
const bus = new Vue({
    i18n,
})
export default bus
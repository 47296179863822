/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-13 17:04:31
 * @LastEditTime: 2021-01-13 17:25:14
 * @FilePath: \acuconference-web\src\store\modules\userInfo\index.js
 */
import actions from './actions'
import mutations from './mutations'
import state from './state'

export default{
  namespaced: true,
  actions, mutations, state
}
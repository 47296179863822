/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 12:00:18
 * @LastEditTime: 2021-01-14 15:10:17
 * @FilePath: \acuconference-web\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

import locales from './modules/locales'
import userInfo from "./modules/userInfo"
import navigationMenuList from "./modules/navigationMenuList"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    locales,
    userInfo,
    navigationMenuList
  }
})

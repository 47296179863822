<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-26 16:32:07
 * @LastEditTime: 2021-03-02 15:50:20
 * @FilePath: \acuconference-web\src\components\dialogs\verfiy2fa.vue
-->
<template>
  <v-dialog v-model="showVerifyDialog" max-width="350" :persistent="loading" @keydown.enter="verify">
    <v-card>
      <v-card-title>
        {{ $t("label_high_security") }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            {{$t('label_2fa_3')}}
          </v-row>
        </v-container>
        <v-form ref="form">
          <v-text-field
            :label="$t('label_2fa_3') + ' *'"
            maxLength="500"
            v-model="code"
            :rules="[
              (v) => !!v || $t('error_tips_required_params')
            ]"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="loading || !code" :loading="loading" @click="verify">
          {{ $t("label_title_btn_verify") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apis from "../../mixins/highSecuApis";
export default {
  mixins: [apis],
  props: {
    value: {
      type: Boolean,
      default: false
    }, 
    forModify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showVerifyDialog: false,
      code: "",
    };
  },
  watch: {
    value(val) {
      if (this.showVerifyDialog !== val) {
        this.showVerifyDialog = val;
      }
    },
    showVerifyDialog(val) {
      if (this.showVerifyDialog !== this.value) {
        this.$emit("input", val);
      }
      if (!val) {
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    async verify() {
      if (!this.code) {
        return
      }
      const code = this.code//, forModify = this.forModify
      const result = await this.verify2fa({
        code,
        forModify: false,
        // forModify
      })
      if (result) {
        // this.$toast.success(this.$t("prompt_success"));
        this.showEdit2FADialog = false;
        this.$emit("refresh", true)
      }
    }
  }
};
</script>

<style lang="sass" scoped>
</style>
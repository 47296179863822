/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-26 16:32:31
 * @LastEditTime: 2021-01-28 16:24:29
 * @FilePath: \acuconference-web\src\mixins\highSecuApis.js
 */
export default {
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async verify2fa(params) {
      this.loading = true;
      const api = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_VERIFY_2FA
      try {
        const result = await this.$axios.post(api, params)
        this.loading = false
        if (result && result.code === 1) {
          return {
            success: true
          }
        }
        throw result
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async genVerifyInfo() {
      this.loading = true;
      const api = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_QR_CODE
      try {
        const result = await this.$axios.get(api)
        this.loading = false
        if (result && result.code === 1) {
          return result.data
        }
        throw result
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async set2FACode(params) {
      this.loading = true
      try {
        const requestApi = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_SET_2FA_CODE
        const result = await this.$axios.post(requestApi, params)
        this.loading = false
        if (result && result.code === 1) {
          return {
            success: true,
          }
        }
        throw result
      } catch (error) {
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    handleError(err) {
      let error = this.$errorTranslate(err)
      if (error) {
        this.$toast.error(error);
      }
    }
    
  },
}
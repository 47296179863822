/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-27 15:01:10
 * @LastEditTime: 2021-03-01 12:08:17
 * @FilePath: \acuconference-web\src\directive\vHideInElectron.js
 */
import Vue from 'vue'
Vue.directive('hideInElectron', {
  bind: function (el, binding, vnode) {
    const hideDom = function () {
      // replace HTMLElement with comment node
      const comment = document.createComment(' ');
      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = ' ';
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;
      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }
    
    if (window.tcConferenceClientManager !== undefined) {
      if (binding.value) {
        hideDom()
      }
    } else {
      if (!binding.value) {
        hideDom()
      }
    }
  }
})
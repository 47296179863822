/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-13 17:04:36
 * @LastEditTime: 2021-01-14 14:38:26
 * @FilePath: \acuconference-web\src\store\modules\userInfo\state.js
 */
/* 

  userInfo: {
    id: '',                // userId
    account: '',          // userAccount
    name: '',             // username
    password: '',         // userPassword
    enableAccount: false, // 是否启用账户,启用了该用户才能用
    email: '',            // userEmail
    mobile: '',           // userMobile
    telephone: '',        // userTelephone
    address: '',          // userAddress
    type: 400,            // userType: [ 100(superAdmin) | 200(operator) | 300(companyAdmin) | 400(nomal) ]
    enterpriseId: '',     // the enterprise that user belongs to
    createTime: 0,        // user create time
    updateTime: 0,        // user update time
    deleteTime: 0,        // user delete time (logic delete)
  }
*/

export default () => ({
  emptyUserInfo: {
    acutoken: '',
    userInfo: {},
    displayPerms: [],
  },
  currentUserInfo: {
    acutoken: '',
    userInfo: {},
    displayPerms: [],
  },
  editUserProfile: {
    
  }
})
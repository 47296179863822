/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 11:57:39
 * @LastEditTime: 2021-03-05 16:17:38
 * @FilePath: \acuconference-web\src\utils\ErrorMap.js
 */

 module.exports = {
  unknowErrorPrompt: "error_unknow_error",
  silenceCode: {
    6037: 'transition acutoken error',
    4001: "error_account_expired",
    6004: "error_password_not_correct",
    6046: "error_password_not_correct"
  },
  errorCodeMap: {
    1038: "error_params_illegal",
    1043: "error_sort_type_invalid",
    4000: "error_must_login",
    6001: "error_user_not_exist",
    6002: "error_domain_not_exists",
    6003: "error_domain_incorrect",
    6005: "error_network_error_no_permission",
    6007: "error_room_in_progress_cant_operating",
    6008: "error_no_such_room",
    6009: "error_password_cannot_be_none",
    6010: "error_incorrect_access_code",
    6012: "error_get_link_error",
    6015: "error_email_illegal",
    6016: "error_old_password_incorrect",
    6018: "error_2fa_code_expired",
    6019: "error_wrong_2fa_code",
    6020: "error_password_is_null",
    6021: "error_inviteCode_exists",
    6022: "error_enterprise_not_enable",
    6023: "error_user_not_enable",
    6024: "error_email_send_failed",
    6025: "error_company_expired",
    6026: "error_password_format_not_match",
    6027: "error_account_exists",
    6029: "error_email_exists",
    6032: "error_domain_exists",
    6034: "error_modify_user_type",
    6035: "error_length_chaoguole_account",
    6036: "error_import_user_server_error",
    6040: "error_max_participants_is_null",
    6041: "error_conferenceService_is_disable",
    6042: "error_please_use_our_template",
    6045: "error_import_format"
  }
 }
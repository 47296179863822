/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 15:05:06
 * @LastEditTime: 2021-01-11 15:37:58
 * @FilePath: \acuconference-web\src\store\modules\locales\index.js
 */
import state from './state'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state, mutations, actions
}
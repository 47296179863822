/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-03-09 15:10:30
 * @LastEditTime: 2021-03-09 15:16:51
 * @FilePath: \acuconference-web\src\plugins\utils\copyText.js
 */
export default function copyText(text) {
  var textArea

  function createTextArea(text) {
    textArea = document.createElement('textArea');
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    textArea.select();
  }

  function copyToClipboard() {
    const result = document.execCommand('copy');
    document.body.removeChild(textArea);
    return result;
  }
  createTextArea(text);
  selectText();
  return copyToClipboard();
}
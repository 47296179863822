/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-13 17:12:58
 * @LastEditTime: 2021-02-22 11:02:34
 * @FilePath: \acuconference-web\src\store\modules\userInfo\mutations.js
 */

import localStorageHandler from "../../../utils/localStorageHandler"
export const NAMESPACE_USER_INFO = "NAMESPACE_USER_INFO"
export const STATE_USERINFO = "_STATE_USERINFO"
export const LOGIN_DATA = "_LOGIN_DATA"

export default {
  updateUserInfo(state, carry) {
    if (typeof carry === 'object') {
      for (const key in carry) {
        state.currentUserInfo[key] = carry[key]
      }
      if (carry.acutoken) {
        // set request headers
        this.axios.defaults.headers.common['acutoken'] = carry.acutoken
      }
      localStorageHandler.set(NAMESPACE_USER_INFO + STATE_USERINFO, state.currentUserInfo)
    }
  },

  setEditUserProfile(state, carry) {
    if (typeof carry === 'object') {
      Object.keys(carry).forEach(key => {
        if (state.editUserProfile[key] !== undefined) {
          state.editUserProfile[key] = carry[key]
        }
      })
    }
  }
}
<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-13 16:27:00
 * @LastEditTime: 2021-03-05 18:16:33
 * @FilePath: \acuconference-web\src\components\navigator.vue
-->
<template>
  <div class="navigation-wrapper">
    <div
      class="user-info-wrapper"
      :style="{
        height: ($vuetify.breakpoint.mobile ? '56' : '64') + 'px!important',
        paddingLeft: '30px'
      }"
    >
      <img :src="logo" alt="" style="position: relative;height:39px;">
    </div>
    <div class="menu-list-wrapper">
      <NavigatorMenuList v-model="navigatorMenuSelect" />
    </div>
    <div class="navigator-tools-wrapper">
      <div class="tools-wrapper">
      </div>
      <div class="tools-wrapper download-wrapper">
        <v-btn
          color="primary"
          :to="{
            name: 'Download',
          }"
          style="padding: 0 8px;"
          v-hideInMobile
        >
          {{ $t("label_Download") }}
        </v-btn>
        <v-btn style="padding: 0 8px;" color="primary" @click="toggleAm" v-hideInElectron="false">
          {{ $t("label_btn_toggle_am") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavigatorMenuList from "./navigatorMenuList/navigatorMenuList";
export default {
  props: ["value"],
  data() {
    return {
      navigatorMenuSelect: "",
    };
  },
  watch: {
    navigatorMenuSelect(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
    },
    value(val) {
      if (val !== this.navigatorMenuSelect) {
        this.navigatorMenuSelect = val;
      }
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    logo() {
      return process.env.VUE_APP_API_RES_HOST + process.env.VUE_APP_API_RES_LOGO
    }
  },
  methods: {
    toggleAm() {
      try {
        window.tcConferenceClientManager &&
          window.tcConferenceClientManager.inputAm();
      } catch (error) {
        process.env.NODE_ENV === 'development' && console.log(error);
      }
    },
  },
  components: {
    NavigatorMenuList,
  },
};
</script>

<style lang="sass" scoped>
.navigation-wrapper
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  .user-info-wrapper
    position: relative
    width: 100%
    flex-shrink: 0
    display: flex
    align-items: center
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)!important
    .username-wrapper
      position: relative
      flex-grow: 1
      display: flex
      box-sizing: border-box
      padding-left: 16px
  .menu-list-wrapper
    position: relative
    flex-grow: 1
    width: 100%
    overflow-x: hidden
    overflow-y: auto
    &::-webkit-scrollbar
      width: 10px
    &::-webkit-scrollbar-track
      background-color: rgba(0, 0, 0, 0)
    &::-webkit-scrollbar-thumb
      width: 8px
      background: #ddd
      border-radius: 999px
      transition: all 0.3s
    &::-webkit-scrollbar-thumb:hover
      background: #ccc
    &::-webkit-scrollbar-button
      background-color: none
  .navigator-tools-wrapper
    position: relative
    width: 100%
    flex-basis: 140px
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding-bottom: 15px
    .tools-wrapper
      position: relative
      width: 100%
      padding: 0 20px
      display: flex
      align-items: center
      justify-content: center
      &.download-wrapper
        display: flex
        justify-content: center
        align-items: flex-start
        *
          flex-grow: 1
        a
          margin-right: 10px
</style>
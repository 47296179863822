/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 15:00:22
 * @LastEditTime: 2021-02-02 16:12:12
 * @FilePath: \acuconference-web\src\store\modules\navigationMenuList\state.js
 */
export default () => ({
  menuList: [
    {
      id: 'meeting',
      label: "label_tab_JoinMeeting",
      icon: 'mdi-login',
      path: 'meeting',
      goto: {
        name: 'Meeting'
      }
    }, {
      id: 'host',
      label: "label_tab_HostMeeting",
      icon: 'mdi-desktop-tower',
      path: 'host',
      goto: {
        name: 'HostMeeting'
      }
    }
  ],
  featureMenuList: [{
    id: 'admin',
    label: 'label_navigation_menu_list_admin',
    icon: 'mdi-account-cog-outline',
    path: 'admin',
    goto: {
      name: 'Admin'
    },
    subList: [{
      id: "configuration",
      label: "label_navigation_menu_list_configuration",
      icon: 'mdi-cog-outline',
      decideBy: "Configuration",
      path: 'configuration',
      goto: {
        name: 'Configuration'
      }
    }, {
      id: "management",
      label: "label_navigation_menu_list_management",
      icon: 'mdi-account-tie',
      decideBy: "Management",
      path: 'management',
      goto: {
        name: 'Management'
      }
    }, {
      id: "conferencelist",
      label: "label_navigation_menu_list_conference_list",
      icon: 'mdi-pencil',
      decideBy: "ConferenceList",
      path: 'conferencelist',
      goto: {
        name: 'ConferenceList'
      }
    }, {
      id: "livesession",
      label: "label_navigation_menu_list_live_sessions",
      icon: 'mdi-video',
      decideBy: "Livesession",
      path: 'livesession',
      goto: {
        name: 'LiveSession'
      }
    }, {
      id: "report",
      label: "label_navigation_menu_list_report",
      icon: 'mdi-file-chart',
      decideBy: "Report",
      path: 'report',
      goto: {
        name: 'Report'
      }
    }, {
      id: "overview",
      label: "label_navigation_menu_list_overview",
      icon: 'mdi-format-list-bulleted-type',
      decideBy: "Overview",
      path: 'overview',
      goto: {
        name: 'Overview'
      }
    }]
  }]
})
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-11 11:57:58
 * @LastEditTime: 2021-01-27 15:56:12
 * @FilePath: \acuconference-web\src\plugins\vuetify.js
 */
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { isDarkMode, listenIsDarkModeChange } from "../utils/darkModeUtil"

let useVuetify = new Vuetify({
  theme: { dark: isDarkMode },
})
Vue.use(Vuetify);

listenIsDarkModeChange(newTheme => {
  useVuetify.framework.theme.dark = newTheme === 'dark'
})
export default useVuetify;

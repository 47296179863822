/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-25 14:50:38
 * @LastEditTime: 2021-01-25 15:11:41
 * @FilePath: \acuconference-web\src\utils\stringUtils.js
 */
export function repla (text, options) {
  let returnUrl = text
  if (typeof options === 'object' && returnUrl) {
    for (const key in options) {
      returnUrl = returnUrl.replace(new RegExp("\\{" + key + "\\}", "g"), options[key])
    }
  }
  return returnUrl
}

<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-26 16:29:06
 * @LastEditTime: 2021-02-07 12:18:24
 * @FilePath: \acuconference-web\src\components\dialogs\heighSecuritySetting.vue
-->
<template>
  <v-dialog v-model="showEdit2FADialog" max-width="600" :persistent="loading" @keydown.enter="submit2FACode">
    <v-card :disabled="loading">
      <v-card-title>
        {{ $t("label_title_set_2fa") }}
      </v-card-title>
      <v-card-subtitle style="margin-top: 10px">
        {{ $t("label_subtitle_set_2fa") }}
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="4" style="padding-right: 0; padding-left: 0">
              <v-img
                :src="QRCodeSrc"
                height="180"
                width="180"
                style="left: 50%; transform: translateX(-50%)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="12" md="8">
              <v-subheader>
                {{ $t("label_2fa_2") }}
              </v-subheader>
              <div
                class="title"
                style="text-align: center; position: relative; margin-top: 40px"
              >
                <strong>
                  {{secPassword}}
                </strong>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>
                {{ $t("label_2fa_3") }}
              </strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ $t("label_2fa_4") }}
            </v-col>
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('label_2fa_3') + ' *'"
              v-model="code"
              maxLength="500"
              :rules="[
                (v) => !!v || $t('error_tips_required_params')
              ]"
              required
            ></v-text-field>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="showEdit2FADialog = false">
          {{$t('label_btn_cancel')}}
        </v-btn>
        <v-btn outlined color="primary" @click="init2FAInfo">
          {{$t('label_btn_refresh')}}
        </v-btn>
        <v-btn color="primary" :disabled="!code" @click="submit2FACode" :loading="loading">
          {{$t('label_btn_save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apis from "../../mixins/highSecuApis"
export default {
  mixins: [apis],
  props: ["value"],
  data() {
    return {
      showEdit2FADialog: false,
      QRCodeSrc: "",
      secPassword: "",
      code: ""
    };
  },
  watch: {
    value(val) {
      if (this.showEdit2FADialog !== val) {
        this.showEdit2FADialog = val;
      }
    },
    showEdit2FADialog(val) {
      if (this.showEdit2FADialog !== this.value) {
        this.$emit("input", val);
      }
      if (!val) {
        this.code = ""
        this.secPassword = ""
        this.QRCodeSrc = ""
      } else {
        this.init2FAInfo()
      }
    },
  },
  created() {
  },
  methods: {
    async init2FAInfo() {
      const result = await this.genVerifyInfo()
      if (result) {
        const { url, password } = result
        this.QRCodeSrc = url
        this.secPassword = password
      }
    },

    async submit2FACode() {
      if (!this.code) {
        return
      }
      const params = {
        password: this.secPassword,
        code: this.code
      }
      const result = await this.set2FACode(params)
      if (result) {
        this.$toast.success(this.$t("prompt_success"));
        this.showEdit2FADialog = false;
        this.$emit("refresh")
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-13 17:13:02
 * @LastEditTime: 2021-02-20 17:57:34
 * @FilePath: \acuconference-web\src\store\modules\userInfo\actions.js
 */
import localStorageHandler from "../../../utils/localStorageHandler"
import { NAMESPACE_USER_INFO, STATE_USERINFO, LOGIN_DATA } from "./mutations"
import { repla } from "../../../utils/stringUtils.js"
import bus from "../../../eventBus/index";
import store from "../../../store/index";
export default {
  async login({ commit }, { domain, account, password } = {}) {
    const passwordAfterBas64 = btoa(password)
    const loginUrl = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_LOGIN
    domain = domain.trim()
    account = account.trim()
    let result = await this.axios.post(loginUrl, {
      domain, account, password: passwordAfterBas64
    })
    process.env.NODE_ENV === 'development' && console.log(result)
    if (result && result.code === 1) {
      commit('updateUserInfo', result.data)
      localStorageHandler.set(NAMESPACE_USER_INFO + LOGIN_DATA, this.secretMaster.encryption(JSON.stringify({
        domain, account, password
      })))
      if (result.data.userInfo.needModifyPassword) {
        return {
          needModifyPassword: true,
        }
      } else {
        return undefined;
      }
    } else {
      const errorLoginCode = [6004, 6046]
      if (errorLoginCode.includes(result.data.code)) {
        //如果用户勾选了自动登录，token过期，自动登录时（errorCode）用户登出页面
        console.log(localStorage.getItem("AUTO_LOGIN"))
        if (result.data.data.residueLoginCount) {
            let residueLoginCount = result.data.data.residueLoginCount
            bus.$toast.error(
              bus.$t("error_password_code", {
                residueLoginCount: residueLoginCount
              }))
          } else if (result.data.data.availableTime) {
            let availableTime = result.data.data.availableTime
            const uniMinutes = 60 * 1000
            const uniSeconds = 1 * 1000

            let minutes = Math.floor(availableTime / uniMinutes) + ''
            let seconds = Math.round((availableTime - minutes * uniMinutes) / uniSeconds) + ''

            bus.$toast.error(
              bus.$t("error_password_availableTime", {
                availableTime: `${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`
              })
            )
        }
        if (localStorage.getItem("AUTO_LOGIN") == 1) {
          store.dispatch("userInfo/logout");
          this.$router.push("/joinmeeting");
        }
      }
      return result
    }
  },

  initUserInfoFromLocalStorage({ commit }) {
    const getFormatObject = true
    const getResult = localStorageHandler.get(NAMESPACE_USER_INFO + STATE_USERINFO, getFormatObject)
    if (typeof getResult === 'object') {
      commit('updateUserInfo', getResult)
    }
  },

  async refreshCurrentUserData({ commit, state }) {
    try {
      const { id } = state.currentUserInfo.userInfo
      if (id) {
        const requestApi = repla(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_USER_INFO, {
          user: id,
        })
        const result = await this.axios.get(requestApi)
        if (result && result.code === 1) {
          const { userInfo } = result.data
          const newCurrentUser = result.data
          commit("updateUserInfo", newCurrentUser)
          if (userInfo.needModifyPassword) {
            return {
              type: 'success',
              needChangePassword: userInfo.needModifyPassword
            }
          }
        }
      }
      return
    } catch (error) {
      return {
        type: 'error',
        error
      }
    }
  },


  logout({ commit, state }) {
    localStorageHandler.remove(NAMESPACE_USER_INFO + STATE_USERINFO)
    localStorageHandler.remove("AUTO_LOGIN")
    commit('updateUserInfo', state.emptyUserInfo)
  },
  // //注销登录
  // async logout({ commit, state }) {
  //   const logoutUrl = repla(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_LOGOUT)
  //   let result = await this.axios.delete(logoutUrl)
  //   if(result){
  //     localStorageHandler.remove(NAMESPACE_USER_INFO + STATE_USERINFO)
  //     localStorageHandler.remove("AUTO_LOGIN")
  //     commit('updateUserInfo', state.emptyUserInfo)
  //   }
  //   if(!state.currentUserInfo.acutoken){
  //     return
  //   }
    
  // },

  relogin({ dispatch }) {
    let loginInfo = localStorageHandler.get(NAMESPACE_USER_INFO + LOGIN_DATA)
    if (typeof loginInfo === 'string') {
      try {
        loginInfo = this.secretMaster.decryption(loginInfo)
        loginInfo = JSON.parse(loginInfo)
      } catch (error) {
        process.env.NODE_ENV === 'development' && console.error(error)
      }
    }
    if (typeof loginInfo === 'object' && loginInfo.domain && loginInfo.account && loginInfo.password) {
      const result = dispatch("login", loginInfo)
      return result
    }
  }
}
<template>
  <v-dialog v-model="showChangePasswordDialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">{{
        $t("title_dialog_change_password")
      }}</v-card-title>
      <v-card-text>
        <v-form ref="changePasswordForm" v-model="valid" :disabled="loading">
          <v-container>
            <v-row>
              <v-col cols="12" v-if="oldPassword">
                <v-text-field
                  :autofocus="true"
                  :label="$t('label_normal_password') + ' *'"
                  v-model="changePasswordValue.password"
                  type="password"
                  maxLength="500"
                  :rules="requiredRules.concat(trim)"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label_normal_new_password') + ' *'"
                  maxLength="500"
                  v-model="changePasswordValue.newPassword"
                  type="password"
                  :rules="unRequiredPasswordCheck.concat(passwordCheckRules)"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label_normal_repeat_new_password') + ' *'"
                  maxLength="500"
                  v-model="changePasswordValue.repeatPassword"
                  type="password"
                  :rules="
                    passwordCheckRules.concat([
                      (v) =>
                        v === changePasswordValue.newPassword ||
                        'password not match',
                    ])
                  "
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :disabled="loading"
          text
          @click="showChangePasswordDialog = false"
          >{{ $t("label_btn_cancel") }}</v-btn
        >
        <v-btn
          color="blue darken-1"
          :disabled="!enableConfirmation"
          text
          @click="confirm"
          v-if="!loading"
          >{{ $t("label_btn_confirm") }}</v-btn
        >
        <div class="loading-wrapper" v-else>
          <v-progress-circular
            indeterminate
            color="primary"
            width="2"
          ></v-progress-circular>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import apis from "../../mixins/apis";
import regCheckRulesMixin from "../../mixins/regCheckRulesMixin";
import localStorageHandler from "../../utils/localStorageHandler"
import { NAMESPACE_USER_INFO, LOGIN_DATA } from "../../store/modules/userInfo/mutations"
export default {
  mixins: [apis, regCheckRulesMixin],
  props: ["value", "userId", "enterpriseId", "forceEditPassword"],
  data() {
    return {
      showChangePasswordDialog: false,
      loading: false,
      valid: false,
      normalPasswordCheck: [
        (v) => !!v || this.$t("error_tips_required_params"),
      ],
      changePasswordValue: {
        password: "",
        newPassword: "",
        repeatPassword: "",
      },
    };
  },

  watch: {
    value(val) {
      if (val !== this.showChangePasswordDialog) {
        this.showChangePasswordDialog = val;
      }
    },
    showChangePasswordDialog(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }

      if (!val) {
        this.$refs.changePasswordForm.reset();
      }
    },
  },

  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    enableConfirmation() {
      return (
        this.valid &&
        this.changePasswordValue.newPassword !== "" &&
        this.changePasswordValue.newPassword ===
          this.changePasswordValue.repeatPassword
      );
    },
    oldPassword() {
      if (this.forceEditPassword) {
        return !this.forceEditPassword;
      }
      return this.currentUserInfo.userInfo.id === this.userId;
    },
  },

  created() {
    this.showChangePasswordDialog = this.value;
  },

  methods: {
    async confirm() {
      const user = {
        id: this.userId,
        newPassword: btoa(this.changePasswordValue.newPassword),
      };
      let result;
      if ((this.currentUserInfo.userInfo.id === this.userId) || this.forceEditPassword) {
        result = await this.changePassword({
          oldPassword: btoa(this.changePasswordValue.password),
          newPassword: btoa(this.changePasswordValue.newPassword),
        });
         let password =this.changePasswordValue.newPassword
         let loginInfo = localStorageHandler.get(NAMESPACE_USER_INFO + LOGIN_DATA)
         try {
           loginInfo = this.$secret.decryption(loginInfo)
           loginInfo = JSON.parse(loginInfo)
         } catch (error) {
          //  catch error
         }
         let domain = loginInfo.domain
         let account = loginInfo.account
         if (domain && account && password) {
            localStorageHandler.set(NAMESPACE_USER_INFO + LOGIN_DATA, this.$secret.encryption(JSON.stringify({
              domain,account,password
          })))
         }
        
      } else {
        //给服务器传修改人的id,和新密码
        result = await this.otherChangePassword(user);
      }
      if (result) {
        this.$toast.success(this.$t("prompt_success"));
        this.showChangePasswordDialog = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
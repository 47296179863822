<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-13 16:34:30
 * @LastEditTime: 2021-03-05 11:02:23
 * @FilePath: \acuconference-web\src\plugins\login\login.vue
-->
<template>
  <v-dialog
    v-model="showLoginDialog"
    max-width="400"
    @keydown.enter="submit"
    @keydown.esc="showLoginDialog = false"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t("title_dialog_login") }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" :disabled="loading">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label_normal_Domain')"
                  v-model="loginData.domain"
                  :rules="requiredRules"
                  maxLength="500"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label_normal_account')"
                  v-model="loginData.account"
                  :rules="requiredRules"
                  maxLength="500"
                  autocomplete="username"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label_normal_password')"
                  v-model="loginData.password"
                  maxLength="500"
                  :rules="requiredRules"
                  type="password"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="setAutoLogin"
          :label="$t('label_check_auto_login')"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :disabled="loading"
          text
          @click="handleCancel"
        >
          {{ $t("label_btn_cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="!valid || timeCount > 0"
          text
          @click="submit"
          v-if="!loading"
        >
          {{ submitBtnRenderText }}
        </v-btn>
        <div class="loading-wrapper" v-else>
          <v-progress-circular
            indeterminate
            color="primary"
            width="2"
          ></v-progress-circular>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import regxCheckMixins from "../../mixins/regCheckRulesMixin";
export default {
  mixins: [regxCheckMixins],
  data() {
    return {
      valid: false,
      showLoginDialog: false,
      loading: false,
      setAutoLogin: false,
      timeCount: 0,
      refreshTimer: null,
      residueLoginTimeCount: null,
      loginData: {
        domain: "",
        account: "",
        password: "",
      },
    };
  },
  computed: {
    formCheckRules() {
      return [(v) => !!v || this.$t("prompt_login_error_all_paramter_needed")];
    },
    ...mapState("userInfo", ["currentUserInfo"]),
    submitBtnRenderText() {
      if (!this.timeCount) {
        return this.$t("label_btn_login_submit");
      } else {
        const unitMinus = 60 * 1000;
        const unitSecond = 1 * 1000;
        const minus = Math.floor(this.timeCount / unitMinus) + "";
        const seconds =
          Math.round((this.timeCount - minus * unitMinus) / unitSecond) + "";
        return `${minus.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
      }
    },
  },
  watch: {
    timeCount(val) {
      if (val <= 0) {
        clearInterval(this.refreshTimer);
        this.timeCount = 0;
      }
    },
  },
  created() {
    if (this.$utils.isMobile()) {
      this.addVisibilityChange();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.showLoginDialog = true;
    });
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
  methods: {
    handleCancel() {
      this.showLoginDialog = false;
    },
    ...mapActions("userInfo", ["login"]),
    async submit() {
      this.loading = true;
      try {
        const res = await this.login(this.loginData);
        if (res) {
          if (res.needModifyPassword) {
            this.$bus.$emit("forceModifiyPassword");
          } else {
            if (res.data && res.data.code) {
              let code = res.data.code;
              if (code === 6004 || code === 6046) {
                const errorCode = [6004, 6046];
                if (errorCode.includes(res.data.code)) {
                  let availableTime = res.data.data.availableTime;
                  if (availableTime) {
                    clearInterval(this.refreshTimer);
                    this.timeCount = availableTime;
                    this.refreshTimer = setInterval(() => {
                      this.timeCount -= 1000;
                    }, 1000);
                  }
                } else {
                  clearInterval(this.refreshTimer);
                  this.timeCount = 0;
                }
              } else if (
                code === 6001 ||
                code === 6002 ||
                code === 6022 ||
                code === 6023 ||
                code === 6025
              ) {
                this.$router.push({
                  name: 'JoinMeeting'
                })
                return this.loading = false;
              }
            }
            throw res;
          }
        }
        //process.env.NODE_ENV === "development" && console.error(res);
        this.$toast.info(
          this.$t("toast_welcome_back", {
            name: this.currentUserInfo.userInfo.name,
          })
        );

        if (this.setAutoLogin) {
          localStorage.setItem("AUTO_LOGIN", "1");
        } else {
          localStorage.removeItem("AUTO_LOGIN");
        }
        this.$destroy();
      } catch (error) {
        console.log('submit:',error)
        // console.log(error,"mhmhmmhmhm")
        // let afterTranslateError = this.$errorTranslate(error);
        // if (afterTranslateError) {
        //   this.$toast.error(afterTranslateError);
        // }
      }
      this.loading = false;
    },
    addVisibilityChange() {
      document.addEventListener("visibilitychange", () => {
        // hidden 为锁屏隐藏状态，visible为重新显示状态
        if (document.visibilityState === "hidden") {
          if (this.showLoginDialog) {
            clearInterval(this.refreshTimer);
            this.refreshTimer = null;
            this.showLoginDialog = false;
          }
        } else if (document.visibilityState === "visible") {
          if (this.showLoginDialog) {
            clearInterval(this.refreshTimer);
            this.refreshTimer = null;
            this.showLoginDialog = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.loading-wrapper
  box-sizing: border-box
  margin: 0 8px
  padding: 0 8px
</style>